@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Module / Payment Form
  */

$card-icon-width: $unit-size-base * 3 - math.div($unit-size-small, 3);
$card-icon-height: $unit-size-base * 2 - math.div($unit-size-small, 3);

.#{$ns}payment-form {
    &__card-icons {
        margin-left: $unit-size-base;

        > img {
            width: $card-icon-width * 0.5;
            height: $card-icon-height * 0.5;

            @include breakpoint(s) {
                position: relative;
                top: -$unit-size-small * 0.5;
                width: $card-icon-width;
                height: $card-icon-height;
            }
        }
    }

    &__current-card {
        margin-left: $unit-size-small*4 + $unit-size-small*0.5; // to position card like the label from radio
    }
}

.#{$ns}payment-stripe-element {
    border: solid 1px $color-input-border;
    border-radius: $common-border-radius;
    line-height: $line-height-base;
    height: $unit-size-small * 7;
    padding: ($unit-size-base - math.div($unit-size-small, 3)) $unit-size-base;
    background: $color-white;
    outline: none;
    color: $color-text-base;
    caret-color: $color-accent-base;
}

.#{$ns}payment-tile {
    position: relative;
    margin-top: $unit-size-base;
    border-radius: $common-border-radius;
    border: math.div($unit-size-small, 3) solid $color-border-light;

    &--selected {
        border: math.div($unit-size-small, 3) solid $color-primary-base;
    }

    &__labels {
        position: absolute;
        top: 0;
        left: $unit-size-small * 3;
        line-height: 1;
        transform: translateY(-50%);
    }
}
