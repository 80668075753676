@import "variables";
@import "mixins";
@import "mixins.product-cards";

$body-side-flex-basis: 50%;
$media-side-flex-basis: 50%;
$card-title-line-height: 1.5;

@mixin button-padding {
    padding-left: $unit-size-base;
    padding-right: $unit-size-base;

    @include breakpoint('m') {
        padding-left: $unit-size-small * 3;
        padding-right: $unit-size-small * 3;
    }
}

/***
  * Module / Project Rewards
  */
.#{$ns}reward-card {
    @include card-edit;
    @include card-desc;

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: $card-title-line-height;

        @include breakpoint("m") {
            -webkit-line-clamp: 2;
        }
    }

    &__body {
        height: 100%;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
        flex-direction: column;
        z-index: 1;
    }

    &__content {
        flex-grow: 1;
    }

    &__desc {
        @include wordbreak;

        font-size: $font-size-base;
        margin-top: $unit-size-small * 2;

        @include breakpoint("m") {
            flex-grow: 0;
            margin-top: $unit-size-small * 3;
        }
    }

    &__action {
        flex-grow: 0;
    }

    &__stats {
        display: flex;
        flex-direction: column;

        @include breakpoint('m') {
            flex-direction: row;
        }
    }

    &__info-bar {
        background-color: $color-background-light;
        padding: $unit-size-small 3* $unit-size-small;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

/***
  * Module / Project without reward
  */
.#{$ns}project-without-reward {
    overflow: hidden;
    box-shadow: $box-shadow-base;
    border-radius: $input-field-border-radius;

    @include breakpoint("m") {
        display: flex;
        flex-wrap: wrap;
    }

    &__currency-field {
        box-shadow: $box-shadow-tight;

        .#{$ns}field__append,
        .#{$ns}field__input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            &:not([readonly]):not([disabled]):active,
            &:not([readonly]):not([disabled]):focus {
                box-shadow: none;
            }
        }

        @include breakpoint("m") {
            flex-basis: 70%;

            .#{$ns}field__input {
                height: 100%;
                border-bottom-left-radius: $input-field-border-radius;
            }

            .#{$ns}field__append {
                height: 100%;
                border-radius: 0;
            }
        }
    }

    &__action {
        flex-grow: 1;
        display: flex;

        @include breakpoint("m") {
            flex-basis: 30%;
        }
    }

    &__button {
        @include button-padding();
    }

    .#{$ns}field__error--tooltip {
        // Explicit number to fix tooltip position without huge modification on global field-error.
        right: 64px;
    }
}
