@use "sass:math";

@import 'mixins';
@import 'variables';

/***
  * Component / Notificator
  */
@mixin notificator-type($name, $bg, $icon, $color: $color-white, $bgBefore: rgba(0, 0, 0, 0.25)) {
    .#{$ns}notificator-item--#{$name} {
        background: $bg;
        color: $color;

        @include breakpoint('s') {
            &::after {
                content: $icon;
            }

            &::before {
                background: $bgBefore;
            }
        }

        @content;
    }
}

.#{$ns}notificator {
    $c: &;

    position: relative;

    &--empty {
        opacity: 0;
        min-height: 0;
        transition: min-height $transition-quick;
    }

    &--not-empty {
        opacity: 1;
        min-height: $unit-size-base * 5;
        transition: opacity $transition-quick;
        margin-bottom: $unit-size-small * 3;
    }

    &-item {
        display: block;
        position: relative;
        background: $color-primary-base;
        color: $color-text-inverse;
        padding: $unit-size-small*3 $unit-size-small*3 $unit-size-small*3 $unit-size-small*3;
        border-radius: $container-border-radius-tight;
        filter: drop-shadow($drop-shadow-tight);

        @include wordbreak;

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            box-shadow: $box-shadow-base;
        }

        @include breakpoint('s') {
            padding-left: $unit-size-base*9;
        }

        &--separator {
            margin-top: $unit-size-base;
        }

        &__title {
            font-weight: $font-weight-bold;
        }

        &__actions {
            margin: auto 0;
            padding-top: $unit-size-base;

            @include breakpoint('m') {
                width: auto;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: $unit-size-base;
                padding-top: 0;
            }
        }

        &__close-button {
            position: absolute;
            cursor: pointer;
            padding: $unit-size-small $unit-size-base;
            top: math.div($unit-size-large, 3);
            right: $unit-size-base;
            font-size: $font-size-big;

            &:hover {
                text-decoration: none;
            }
        }

        &__buttons {
            position: absolute;
            top: math.div($unit-size-small, 3) * 5;
            right: $unit-size-small * 4;
        }

        &__progress {
            position: absolute;
            top: $unit-size-small * 3;
            right: $unit-size-small * 4;
        }

        &::before {
            @include breakpoint('s') {
                content: ' ';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: $unit-size-base * 7;
                background: rgba(0, 0, 0, 0.25);
                border-radius: $container-border-radius-tight 0 0 $container-border-radius-tight;
            }
        }

        &::after {
            @include breakpoint('s') {
                display: block;
                position: absolute;
                top: 50%;
                left: $unit-size-small * 7;
                transform: translate(-50%, -50%);
                content: '\f05a';
                font-family: $font-family-icons;
                font-size: $font-size-huge;
            }
        }

        &--progress {
            &::after {
                @include breakpoint('s') {
                    animation: notificator-progress-spin 1s infinite;
                    content: '\f021';
                }
            }
        }

        &--permanent {
            cursor: default;

            &#{$c} {
                &__close-button {
                    display: none;
                }
            }
        }

        &--has-actions {
            @include breakpoint('m') {
                padding-right: $unit-size-base*9;
            }
        }

        &--has-close-button {
            padding-right: $unit-size-base*4;
        }

        &--fill-mobile {
            @include breakpoint('m', max-width) {
                padding: $unit-size-small * 3;
            }
        }

        &--has-image {
            padding-left: $unit-size-base * 7;

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        &-image {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: $unit-size-base * 7;

            &-box {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: $unit-size-base * 7;
                height: 100%;

                &-inner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $color-white;
                    border-radius: $common-border-radius;
                    padding: math.div($unit-size-small, 3);
                    box-shadow: $box-shadow-tight;
                    height: $unit-size-base * 4;
                    width: $unit-size-base * 4;
                }

                img {
                    background-color: $color-white;
                    border-radius: $common-border-radius;
                    width: 100%;
                    height: 100%;
                    margin: $unit-size-small 0;

                    @include breakpoint('s') {
                        margin: 0;
                    }
                }
            }
        }
    }

    &--popup {
        @include navbar-height(top, $unit-size-small);

        position: fixed;
        right: auto;
        left: $unit-size-small * 3;
        z-index: $z-floating-notification + 1;
        margin-bottom: 0;

        @include breakpoint('xl') {
            top: auto;
            bottom: $unit-size-small * 3;
        }

        #{$c}-item {
            width: calc(100vw - #{2 * $unit-size-small * 3});
            font-size: $font-size-base;
            line-height: $unit-size-small * 3;
            padding: ($unit-size-small * 2) ($unit-size-base * 4) ($unit-size-small * 2) ($unit-size-small * 2);

            @include breakpoint('m') {
                width: $unit-size-base * 24;
            }

            @include breakpoint('xl') {
                top: auto;
                bottom: 0;
            }

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }

            &__title {
                display: block;
                font-weight: $font-weight-extrabold;
            }

            &--has-image {
                padding-left: $unit-size-small * 8 + $unit-size-small * 0.5; // 6px + 36px + 9px
            }

            &__close-button {
                height: 100%;
                top: 0;
                right: $unit-size-small;
                display: inline-flex;
                align-items: center;
                font-size: $font-size-plus;
            }
        }

        #{$c}-item-image {
            width: $unit-size-small * 8;
        }

        #{$c}-item-image-box {
            min-width: $unit-size-small * 8;
            padding-top: $unit-size-small;
            align-items: flex-start;
        }

        #{$c}-item-image-box-inner {
            width: $unit-size-base * 3;
            height: $unit-size-base * 3;
            padding: 0;
        }

        @include notificator-type('success', $color-message-success-alt, '\f00c');
    }
}

@keyframes notificator-progress-spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@include notificator-type('success', $color-message-success, '\f00c');
@include notificator-type('error', $color-message-error, '\f06a');
@include notificator-type('warning', $color-message-warning, '\f06a', $color-primary-dark, rgba(255, 255, 255, 0.25));
@include notificator-type('positive', $color-message-success, '\f118'); // smile icon
@include notificator-type('negative', $color-primary-base, '\f7a9'); // broken-heart icon

/***
* Notification Slide Up & Fade Animation
*/

.#{$ns}notification-slide-and-fade-enter-active,
.#{$ns}notification-slide-and-fade-leave-active {
    transition: all $transition-timing-quick ease-in;
}

.#{$ns}notification-slide-and-fade-enter,
.#{$ns}notification-slide-and-fade-leave-to {
    opacity: 0;
}

.#{$ns}notification-slide-and-fade-enter {
    transform: translateY($unit-size-base);
}

.#{$ns}notification-slide-and-fade-leave-to {
    transform: translateY(-$unit-size-base);
}

@include breakpoint('m') {
    .#{$ns}notification-slide-and-fade-enter {
        transform: translateY(-$unit-size-base);
    }

    .#{$ns}notification-slide-and-fade-leave-to {
        transform: translateY($unit-size-base);
    }
}
