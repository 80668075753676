@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Block editor
  */

$block-editor-prefix-width: $unit-size-small * 5;

.#{$ns}block-editor {
    position: relative;
    display: flex;
    background-color: $color-background-light;
    border-radius: $common-border-radius;
    color: $color-text-gray;

    &:hover {
        background-color: $color-divider-light;

        .#{$ns}block-editor__number {
            background-color: $color-text-gray;
        }
    }

    &--nohover:hover {
        background-color: $color-background-light;

        .#{$ns}block-editor__number {
            background-color: $color-background-light;
        }
    }

    &--dragging {
        background-color: $color-accent-base !important;
        color: $color-white !important;

        .#{$ns}block-editor__prefix > * {
            opacity: 0.5;
        }

        .#{$ns}block-editor__number {
            background-color: $color-white !important;
            color: $color-accent-base !important;
        }
    }

    &--drag-up,
    &--drag-down,
    &.is-drop-target-top,
    &.is-drop-target-bottom {
        background: $color-background-light;

        .#{$ns}block-editor__content-wrapper {
            pointer-events: none;
        }

        &::before {
            content: '';
            width: 100%;
            position: absolute;
            border-bottom: solid 3px $color-text-accent;
        }
    }

    &--drag-left {
        background: $color-background-light;
        pointer-events: none;

        > * {
            pointer-events: none;
        }

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border-left: solid 3px $color-text-accent;
        }
    }

    &--drag-left::before {
        left: (-$grid-space-size-m*0.25) - 1;

        @include breakpoint(l) {
            left: (-$grid-space-size-l*0.5) - 1;
        }

        @include breakpoint(xl) {
            left: (-$grid-space-size-xl*0.5) - 1;
        }
    }

    &--drag-up, &.is-drop-target-top {
        &::before {
            top: -24px;
        }
    }

    &--drag-down, &.is-drop-target-bottom {
        &::before {
            bottom: -9px;
        }
    }

    &--compact.#{$ns}block-editor--drag-up,
    &--compact.is-drop-target-top {
        &::before {
            top: -6px;
        }
    }

    &--compact.#{$ns}block-editor--drag-down,
    &--compact.is-drop-target-bottom {
        &::before {
            bottom: -7px;
        }
    }

    &--light {
        border: 1px solid $color-input-border;

        &:hover {
            border-color: $color-text-lighter;
        }

        &, &:hover, .#{$ns}block-editor__content, .#{$ns}block-editor__content-wrapper, .#{$ns}block-editor__media-placeholder {
            background: transparent;
        }
    }

    &--warning {
        background-color: $color-warning;

        &:hover {
            background-color: darken($color-warning, 10%);
        }
    }

    &--warning#{&}--nohover:hover {
        background-color: $color-warning;
    }

    &--error {
        background-color: $color-error;

        &:hover {
            background-color: darken($color-error, 10%);
        }
    }

    &--error#{&}--nohover:hover {
        background-color: $color-error;
    }

    &__tags {
        position: absolute;
        left: $unit-size-base * 3;
        top: 0px;
        transform: translateY(-50%);
    }

    &__holder {
        display: flex;
        width: 100%;
        border-radius: $common-border-radius;
    }

    &__media-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: $unit-size-small * 0.5;
    }

    &__media {
        min-width: 100%;
        background-color: $color-white;
        border-radius: $common-border-radius - 2;
        background-position: 50% 50%;
        background-size: cover;

        &:hover {
            cursor: pointer;
        }

        &.disabled:hover {
            cursor: default;
        }
    }

    &__media-placeholder {
        text-align: center;
        justify-content: center;
        background-color: $color-white;
        color: $color-text-lighter;
        border-radius: $common-border-radius - 2;

        &:hover {
            color: $color-text-gray;
        }

        &::before {
            font-size: $font-size-big;
        }

        &:first-child:not(:only-child) {
            margin-left: 0;
            margin-right: $unit-size-small * 0.5;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__content-wrapper {
        position: relative;
        display: flex;
        width: 100%;
    }

    &__content {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: $unit-size-small * 0.5;
        background-color: $color-white;
        border-radius: $block-border-radius;
        min-height: $unit-size-small * 8;

        > span {
            align-self: center;
        }

        &--transparent {
            background-color: transparent;
        }

        &--empty {
            /*Disable text highlighting*/
            user-select: none;
            cursor: pointer;
            align-items: center;
        }
    }

    &__number {
        width: $unit-size-small * 3 - math.div($unit-size-small, 3);
        height: $unit-size-small * 3 - math.div($unit-size-small, 3);
        line-height: $unit-size-small * 3 - math.div($unit-size-small, 3);
        border-radius: $unit-size-base;
        background-color: $color-text-lighter;
        color: $color-background-light;
        font-family: $font-family-base;
        font-weight: $font-weight-extrabold;
        font-size: $font-size-nano;
        text-align: center;
        user-select: none;
    }

    &__actions {
        position: absolute;
        top: -$unit-size-small * 5 * 0.5;
        right: $unit-size-small * 5;
    }
}

.#{$ns}block-editor-prefix {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-family-icons;
    width: $block-editor-prefix-width;
    margin: $unit-size-small * 0.5 0 $unit-size-small 0;

    > * {
        margin-top: ($unit-size-small * 0.5);
    }

    &--draggable {
        height: 100%;

        &:hover {
            cursor: grab;
        }
    }
}

.#{$ns}block-editor-prefix-icon {
    &--centered {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}
