@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Modals
  */

.#{$ns}modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $color-shadow-darker;
    z-index: $z-modal;
    overflow-y: auto;
    display: none;

    &::after {
        display: block;
        content: '';
        height: 50px;
    }

    &--visible {
        display: block;
    }

    &--scrollable {
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    &__body {
        width: $grid-column-size-l * 7 + $grid-space-size-l * 8;
        max-width: 90%;
        position: absolute;
        padding: $unit-size-small * 3;
        margin-top: 10%;
        margin-bottom: 10%;
        border-radius: $container-border-radius;
        color: $color-text-dark;
        background-color: $color-white;
        box-shadow: $color-shadow-dark 0 0 5px;
        left: 50%;
        transform: translate(-50%, 0);
        outline: none;
        display: flex;
        flex-direction: column;

        @include breakpoint('m') {
            padding: $unit-size-base*3 $unit-size-base*3;
        }

        &--narrow {
            width: $grid-column-size-l * 6 + $grid-space-size-l * 7;
        }

        &--wide {
            width: 900px;
            margin-top: $unit-size-base * 5;
            margin-bottom: $unit-size-base * 5;
        }

        &--fullscreen {
            margin: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            max-height: none;
            max-width: none;
            padding: 0;
            border-radius: 0;
            box-shadow: none;
            overflow-y: auto;
            overflow-x: hidden;
            transform: none;

            @include breakpoint('xl') {
                padding: $unit-size-small * 6;
                padding-bottom: 0;
            }
        }
        
        &--fit {
            width: auto;
            margin-top: $unit-size-small * 5;
            padding: $unit-size-small * 6 $unit-size-half * 15 $unit-size-half * 3;
        }
    }

    &__header {
        text-align: left;
        margin-bottom: $unit-size-small * 3;
        margin-top: 0;
        font-family: $font-family-base;
        font-size: $font-size-big;
        font-weight: $font-weight-extrabold;
        color: $color-text-dark;

        &-icon {
            font-size: $font-size-super;
            display: inline-block;
            margin-right: $unit-size-base;
        }

        @include breakpoint('s') {
            font-size: $font-size-super;
        }
    }

    &__header-text {
        margin-top: math.div($unit-size-small, 3) * 2;
        width: max-content;
        margin-right: $unit-size-small * 3;
    }

    &__content {
        font-size: $font-size-base;
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__footer {
        &-actions {
            @include breakpoint('s', max-width) {
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: flex-end;
            }

            *:first-child {
                margin-left: 0;
            }
        }
    }

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 26px; // So user can scroll in modal
        bottom: 0;
        height: 100%;
    }
}

/***
  * Component / Modals navigation
  */

.#{$ns}modal-navigation {
    display: flex;
    padding: $unit-size-small * 2;
    position: sticky;
    width: 100%;
    top: 0;
    background-color: $color-white;
    z-index: 1;

    &--has-title {
        width: max-content;
    }

    @include breakpoint('m') {
        margin-top: -$unit-size-small * 4;
        min-height: $unit-size-small * 6;
        padding: $unit-size-small * 2 0;
    }

    &__icon {
        position: relative;
        top: 1px;
        font-size: $font-size-extra;
    }
}
