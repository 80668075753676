@import 'variables';
@import 'mixins';


/***
  * Component / Sticky Table
  */

@mixin table-grid() {
    @for $i from 0 through 50 {
        &--columns-#{$i} {
            grid-template-columns: repeat($i, 108px);
            @include breakpoint('xl') {
                grid-template-columns: 300px repeat($i, 108px);
            }
        }
    }
}

@mixin order($prefix: '', $orders: 1) {
    @for $prop from 1 through $orders {
        ._order-#{$prop}#{$prefix} {
            order: $prop
        }
    }
  }

.#{$ns}sticky-table {
    display: inline-grid;
    @include table-grid();
    column-gap: $unit-size-small / 2;
    @include breakpoint('xl') {
        gap: $unit-size-small / 2;
    }

    &--narrow {
        display: grid;
    }

    &__header {
        display: contents;
        * > th {
            position: sticky;
            z-index: 2;
            left: 0px;
            top:0;

            //Separator
            &:first-child {
                position: sticky;
                background-color: $color-white;
                z-index: 5;
            }
        }
    }

    &__body {
        display: contents;
        * > th {
            position: sticky;
            left: 0;
            background-color: $color-white;
            z-index: 2;
        }
    }
}