@import 'variables';
@import 'mixins';

/***
  * Component / Nav
  */

$nav-actions-width: $unit-size-base * 7;
$nav-actions-double-width: $unit-size-small * 13;
$nav-actions-triple-width: $unit-size-small * 19;

.#{$ns}nav {
    @include navbar-height('min-height');
    width: 100%;
    font-size: $font-size-base;
    line-height: $line-height-base;
    white-space: nowrap;
    position: relative;
    z-index: $z-nav;
    transition: transform $transition-slow;
    background-color: $navbar-bg-color-main;
    color: $navbar-text-color-main;
    list-style: none;
    margin: 0;
    padding: 0;

    @include breakpoint('m') {
        box-shadow: $box-shadow-base;
    }

    @include layout('full');

    *:focus {
        outline: none;
    }

    &--main {
        font-size: $font-size-plus;
        z-index: $z-admin-nav;

        .#{$ns}nav__item {
            /*TODO: Delete to complete merging of nav styles*/
            padding-bottom: $unit-size-base;
            padding-top: ($unit-size-base - $unit-size-small*0.5);
            border-bottom: none;
            border-top: $unit-size-small*0.5 solid transparent;

            &--selected {
                border-top-color: $color-text-accent;
            }
        }

        .#{$ns}nav-dropdown {
            /*TODO: Delete to complete merging of nav styles*/
            padding: 0;
            border: none;
            cursor: pointer;

            &__trigger {
                padding: $unit-size-base ( $unit-size-small*3 + $unit-size-small * 5) ($unit-size-base - $unit-size-small*0.5) $unit-size-small*3;
                padding-top: $unit-size-base - $unit-size-small*0.5;
                padding-bottom: $unit-size-base;
                border-bottom: none;
                border-top: $unit-size-small*0.5 solid transparent;
            }

            &__content {
                min-width: 200%;

                &--columns2 {
                    min-width: 400%;
                }

                &--columns3 {
                    min-width: 600%;
                }
            }
        }
    }

    &--floating {
        position: fixed;
        top: 0;

        &-mobile {
            z-index: $z-nav + 3;
        }
    }

    &--hidden {
        transform: translateY(-100%);
        pointer-events: none;
        box-shadow: none;
    }

    &--disabled {
        pointer-events: none;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: $z-floating-notification;
            background: $color-shadow-dark;
        }
    }

    &__layout {
        position: relative;
        display: flex;
    }

    &-wrapper {
        @include navbar-height('min-height');
    }


    &__content {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        line-height: $navbar-line-height;
        max-width: 100%;
        width: 100%;

        &--large-screen {
            display: none;

            @include breakpoint('m') {
                display: flex;
            }
        }

        &--small-screen {
            @include breakpoint('m') {
                display: none;
            }
        }

        &--vertical {
            flex-direction: column;
        }
        // Handle content's width when its container is flex (e.g. SliderContainer)
        &--has-actions {
            max-width: calc(100% - #{$nav-actions-width});

            &-double {
                max-width: calc(100% - #{$nav-actions-double-width});
            }

            &-triple {
                max-width: calc(100% - #{$nav-actions-triple-width});
            }
        }
    }

    &__actions {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        z-index: $z-nav + 1;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: nowrap;
        line-height: $navbar-line-height;
        min-width: $nav-actions-width;

        @include breakpoint('xl') {
            margin-right: - $unit-size-base;
        }

        &--double {
            min-width: $nav-actions-double-width;
        }

        &--triple {
            min-width: $nav-actions-triple-width;
        }

        &--large-screen {
            display: none;

            @include breakpoint('m') {
                display: flex;
            }
        }

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }

        &--left {
            left: 0;
            right: auto;
            margin-right: 0;
            justify-content: flex-start;

            @include breakpoint('xl') {
                margin-left: - $unit-size-base;
            }
        }
    }

    &__text {
        display: block;
        max-width: $unit-size-large * 15;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__item {
        @include navbar-height();
        position: relative;
        display: flex;
        flex: 0 0 auto;
        text-align: center;
        line-height: inherit;
        padding: $unit-size-base $unit-size-small*3;
        padding-bottom: ($unit-size-base - $unit-size-small*0.5);
        border-bottom: $unit-size-small*0.5 solid transparent;
        text-shadow: none;
        transition: border-color $transition-quick;

        &--action {
            padding: $unit-size-base $unit-size-base * 0.5;

            @include breakpoint('xl') {
                padding: $unit-size-base $unit-size-base;
            }

            &:hover {
                text-decoration: none;
                cursor: pointer;
            }

            &-big {
                font-size: $font-size-super;
            }

            &-bold {
                font-weight: $font-weight-bold;
            }
        }

        &--selected {
            font-weight: $font-weight-bold;
            border-color: $color-accent-inverse;

            .#{$ns}nav-dropdown__trigger:not(.#{$ns}nav-dropdown__trigger--sub) {
                border-color: $color-accent-inverse;
            }
        }

        &--featured {
            color: $color-accent-inverse;
            font-weight: $font-weight-bold;

            &:hover * {
                text-shadow: none;
                opacity: 1;
            }
        }

        &--right {
            margin-left: auto;
            padding-right: 0;
        }

        &--title {
            padding-left: 0;
        }

        &--wide {
            padding-right: $unit-size-large;
            padding-left: $unit-size-large;
        }

        &--centered {
            padding-top: $unit-size-small * 3;
            padding-bottom: $unit-size-small * 3;
        }

        &--no-border {
            border: none;
        }

        &--compact-mobile {
            padding-left: $unit-size-base - $unit-size-small*0.5;
            padding-right: $unit-size-base - $unit-size-small*0.5;
            max-width: $unit-size-large * 6;
            white-space: normal;
            line-height: $line-height-small;
            text-overflow: ellipsis;

            @include wordbreak;

            @include breakpoint('m') {
                line-height: $navbar-line-height;
                max-width: none;
                padding: $unit-size-base $unit-size-small*3;
                padding-bottom: ($unit-size-base - $unit-size-small*0.5);
            }

            .#{$ns}nav-dropdown__trigger {
                height: 100%;

                &-content {
                    max-height: $unit-size-large * 3;
                    overflow: hidden;
                }

                &::after {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &,
            .#{$ns}nav-dropdown__trigger {
                overflow: hidden;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                -webkit-box-pack: center;
                display: -webkit-box !important;
            }
        }
    }

    &--secondary {
        background-color: $navbar-bg-color-secondary;

        .#{$ns}nav {
            &-dropdown {
                &__trigger {
                    transition: background-color $transition-default;
                    background-color: $navbar-bg-color-secondary;
                }

                &__content {
                    transition: background-color $transition-default, opacity $transition-quick;
                    background-color: $navbar-bg-color-secondary;
                }
            }
        }
    }

    &--light {
        background-color: $color-background-light;
        color: $color-text-gray;

        .#{$ns}nav {
            &-dropdown {
                &__trigger {
                    background-color: $color-background-light;
                }

                &__content {
                    background-color: $color-background-light;
                    color: $color-text-gray;
                }
            }

            &__item {
                &--featured {
                    border-color: $color-text-primary;
                    color: inherit;
                    text-shadow: none;
                }
            }
        }
    }

    &--lighter {
        transition: background-color $transition-default;
        background-color: $color-background-lighter;
        color: $color-text-gray;

        .#{$ns}nav {
            &-dropdown {
                &__trigger {
                    transition: background-color $transition-default;
                    background-color: $color-background-lighter;
                }

                &__content {
                    transition: background-color $transition-default, opacity $transition-quick;
                    background-color: $color-background-lighter;
                }
            }
        }
    }
}

/***
  * Component / Nav Select
  */

.#{$ns}nav-select {
    margin-top: $unit-size-small;
    margin-bottom: $unit-size-small;
}


/***
  * Component / Nav dropdown
  */

.#{$ns}nav-dropdown {
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &:focus {
        outline: none;
    }

    &__trigger {
        background-color: $navbar-bg-color-main;
        padding: $unit-size-base ( $unit-size-small*3 + $unit-size-small * 5) ($unit-size-base - $unit-size-small*0.5) $unit-size-small*3;
        border-bottom: $unit-size-small*0.5 solid transparent;

        &::after {
            content: '\f078';
            position: absolute;
            right: $unit-size-small * 3;
            font-family: $font-family-icons;
            font-size: $font-size-big;
            text-shadow: none;
            color: $color-accent-base;
        }

        &:hover::after {
            text-decoration: underline; // Fix for Edge underline
        }

        &:hover::after {
            text-decoration: none;
        }

        &--active {
            background-color: $navbar-bg-color-secondary;
            transition: background-color $transition-quick;
        }

        &--has-arrow::before {
            opacity: 1;
        }

        &--sub#{&}--active {
            background-color: $navbar-bg-color-tertiary;
        }

        &--sub {
            padding: $unit-size-small $unit-size-small*3;

            &::after {
                content: '\f054';
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--expanded {
        border-color: transparent;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: absolute;
        overflow: hidden;
        list-style-type: none;
        z-index: $z-nav - 1;
        margin-top: 0;
        top: $navbar-height;
        margin-left: 0;
        transition: opacity $transition-quick;
        opacity: 0;
        pointer-events: none;
        box-shadow: $box-shadow-base;
        clip-path: polygon(-10% 0%, 110% 0%, 110% 110%, -10% 110% );
        min-width: $nav-dropdown-min-width;
        line-height: $navbar-line-height;
        background-color: $navbar-bg-color-secondary;
        color: $navbar-text-color-main;

        @include breakpoint('s') {
            max-height: $nav-dropdown-max-height;

            &--columns2 {
                min-width: $nav-dropdown-min-width*2;
                height: $nav-dropdown-max-height;
            }

            &--columns3 {
                min-width: $nav-dropdown-min-width*3;
                height: $nav-dropdown-max-height;
            }

            &--columns4 {
                min-width: $nav-dropdown-min-width*4;
                height: $nav-dropdown-max-height;
            }

            &--columns5 {
                min-width: $nav-dropdown-min-width*5;
                height: $nav-dropdown-max-height;
            }
        }

        &--expanded {
            color: inherit;
            opacity: 1;
            pointer-events: all;
        }

        &--left {
            right: 0;
        }

        &--no-transition {
            transition: none;
        }
    }

    &__item {
        width: 100%;
        margin: 0;
        text-align: left;
        padding: $unit-size-small $unit-size-small*3;
        padding-bottom: ($unit-size-small - $unit-size-small*0.5);
        border-bottom: 3px solid transparent;
        white-space: nowrap;
        overflow: hidden;
        font-weight: $font-weight-normal;
        min-width: $nav-dropdown-min-width;
        text-shadow: none;

        &--columns2 {
            width: 50%;
        }

        &--columns3 {
            width: 33%;
        }

        &--columns4 {
            width: 25%;
        }

        &--columns5 {
            width: 20%;
        }

        &--selected {
            font-weight: $font-weight-bold;
            border-color: $color-accent-base;
        }

        &--featured {
            font-weight: $font-weight-bold;
            border-color: $color-accent-base;
        }
    }

    &__link {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
