@import 'variables';
@import 'mixins';

/***
  * Basic / Animation
  */

.#{$ns}animation-blink {
    animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

/***
* Basic / Animation
*/

.#{$ns}animation-shake {
    animation: shake 0.25s ease-in-out infinite;
}

@keyframes shake {
    25% {
        transform: rotate(-12deg);
    }

    37.5% {
        transform: rotate(-24deg);
    }

    50% {
        transform: rotate(-12deg);
    }

    62.5% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(12deg);
    }

    87.5% {
        transform: rotate(24deg);
    }

    100% {
        transform: rotate(12deg);
    }
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
        transform-origin: center center;
    }

    100% {
        transform: rotate(360deg);
        transform-origin: center center;
    }
}

@keyframes beat {
    0% {
        transform: scale(0);
    }

    25% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(0.8);
    }

    75% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

/***
* Basic / Fade Transition
*/

.#{$ns}fade-leave-active,
.#{$ns}fade-enter-active {
    transition: opacity $transition-timing-quick ease-in;
}

.#{$ns}fade-enter,
.#{$ns}fade-leave-to {
    opacity: 0;
}

/***
* Slide Up Animation
*/

.#{$ns}slide-up-leave-active,
.#{$ns}slide-up-enter-active {
    transition: transform $transition-timing-quick ease-in;
    transform: translateY(0);
}

.#{$ns}slide-up-enter,
.#{$ns}slide-up-leave-to {
    transform: translateY(100%);
}

/***
* Icon Click Animation
*/

@keyframes icon-animation-down {
    0% {
        transform: translateY(-50%) rotate(90deg);
    }

    50% {
        transform: translateY(-40%) rotate(90deg);
    }

    100% {
        transform: translateY(-50%) rotate(90deg);
    }
}

@keyframes icon-animation-up {
    0% {
        transform: translateY(-50%) rotate(90deg);
    }

    50% {
        transform: translateY(-40%) rotate(90deg);
    }

    100% {
        transform: translateY(-50%) rotate(90deg);
    }
}

/***
* Slider slide next animation
*/
.#{$ns}slide-next-group-move {
    z-index: 2;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-next-group-leave,
.#{$ns}slide-next-group-enter-to {
    opacity: 1;
    z-index: 2;
}

.#{$ns}slide-next-group-enter {
    opacity: 0;
    transform: translateX(100%) scale(0.5);
}

.#{$ns}slide-next-group-leave-to {
    opacity: 0;
    transform: translateX(-100%) scale(0.5);
}

.#{$ns}slide-next-group-enter-active {
    z-index: 1;
    right: 0;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-next-group-leave-active {
    position: absolute;
    z-index: 1;
    left: 0.01;
    transition: all $transition-timing-default ease;
}

/***
* Slider slide prev animation
*/
.#{$ns}slide-prev-group-move {
    z-index: 2;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-prev-group-leave,
.#{$ns}slide-prev-group-enter-to {
    opacity: 1;
    z-index: 2;
}

.#{$ns}slide-prev-group-enter {
    opacity: 0;
    transform: translateX(-100%) scale(0.5);
}

.#{$ns}slide-prev-group-leave-to {
    opacity: 0;
    transform: translateX(100%) scale(0.5);
}

.#{$ns}slide-prev-group-enter-active {
    left: 0;
    z-index: 1;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-prev-group-leave-active {
    position: absolute;
    right: 0;
    z-index: 1;
    transition: all $transition-timing-default ease;
}

.#{$ns}animation-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}