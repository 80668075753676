﻿@import 'variables';
@import 'mixins';


/***
  * Helpers / Borders
  */

  ._bd--default {
    border: 1px solid transparent;
  }

  ._bd--thick {
    border: 2px solid transparent;
  }

  ._bdb--default {
    border-bottom: 1px solid transparent;
  }

  ._bdb--thick {
    border-bottom: 2px solid transparent;
  }

/***
  * Helpers / Radius
  */

._rounded--input {
    border-radius: $input-field-border-radius;
}

._rounded--common {
    border-radius: $common-border-radius;
}

._rounded--large {
    border-radius: $unit-size-large;
}

/***
  * Helpers / Colors
  */

._bc--primary {
    border-color: $color-primary-base;
}

._bc--accent {
    border-color: $color-accent-base;
}

._bc--input {
    border-color: $color-input-border;
}

._bc--light {
    border-color: $color-border-light;
}

._bc--disabled {
    border-color: $color-text-disabled;
}
