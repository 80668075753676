@import 'variables';
@import 'mixins';

/***
  * Module / Layout
  */

.#{$ns}layout {
    margin: 0 auto;
    min-width: $layout-min-width;
    overflow-anchor: none;

    &__content {
        @include clearfix;

        @include breakpoint(l) {
            min-height: 70vh;
        }
    }

    &__main {
        @include layout;

        padding-bottom: $unit-size-large + $unit-size-base;

        &--narrow {
            @include layout('narrow');
        }

        &--extranarrow {
            @include layout('extranarrow');
        }
    }

    &__section {
        &--gray {
            background-color: $color-background-light;

            &:last-of-type {
                padding-bottom: $unit-size-small * 6;
            }
        }
    }

    &__header {
        &.is-sticky {
            position: sticky;
            top: 0;
            z-index: $z-admin-nav;
        }

        &.is-sticky-m {
            @include breakpoint(m) {
                position: sticky;
                top: 0;
                z-index: $z-header;
            }
        }
    }

    &.is-hidden {
        display: none;
    }
}

.#{$ns}layout-bar {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 100%;
    z-index: $z-layout-bar;

    &--bottom {
        bottom: -1px;
    }

    &--top {
        top: 0;
    }
}

.#{$ns}layout-wrapper {
    @include layout;

    &--narrow {
        @include layout('narrow');
    }

    &--extranarrow {
        @include layout('extranarrow');
    }
}