@import 'variables';
@import 'mixins';

/***
  * Module / Project Featured Banner
  */

$banner-max-width-l: $unit-size-large * 18;
$banner-max-width-xl: $unit-size-large * 22;

$banner-height-s: $unit-size-small * 50;
$banner-height-m: $unit-size-small * 55;
$banner-height-l: $unit-size-small * 83;

.#{$ns}project-featured-banner {
    position: relative;
    overflow: hidden;
    height: $banner-height-s;
    background: $color-background-dark;
    box-shadow: $box-shadow-base;

    @include breakpoint('s') {
        height: $banner-height-m;
    }

    @include breakpoint('l') {
        height: $banner-height-l;
        box-shadow: none;
    }

    &__content {
        text-align: center;
        display: inline-block;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-40%);

        @include breakpoint('l') {
            text-align: left;
            left: auto;
            right: auto;
            max-width: $banner-max-width-l;
            transform: translateY(-50%);
            margin-top: $unit-size-small;
        }

        @include breakpoint('xl') {
            max-width: $banner-max-width-xl;
        }
    }

    &__title {
        color: $color-text-accent;
        text-shadow: $text-shadow-blurred;
        line-height: $line-height-condensed;
        padding: 0 $unit-size-base;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include breakpoint(s) {
            font-size: $font-size-enormous;
            line-height: $unit-size-small * 9;
        }

        @include breakpoint('l') {
            padding: 0;
        }

        a:hover & {
            text-decoration: underline;
        }
    }

    &__description {
        font-size: $font-size-base;
        text-shadow: 0 2px 4px $color-shadow-darker;
        padding: 0 $unit-size-base;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;

        @include breakpoint('m') {
            font-size: $font-size-plus;
        }

        @include breakpoint('l') {
            padding: 0;
        }
    }

    &__action {
        margin-top: $unit-size-small * 3;
        margin-bottom: $unit-size-small * 4;

        @include breakpoint('m') {
            margin-bottom: $unit-size-base * 3;
        }
    }

    &__paging {
        letter-spacing: $unit-size-small;
    }

    &__dots-container {
        position: absolute;
        top: 3%;
        left: 0;
        width: 100%;
        text-align: center;
        z-index: 2;

        @include breakpoint('s') {
            top: 10%;
        }

        @include breakpoint('m') {
            top: 5%;
        }

        @include breakpoint('l') {
            top: 18%;
            text-align: left;
        }
    }

    &__dot {
        display: inline-block;
        font-size: 0;
        width: $unit-size-base;
        height: $unit-size-base;
        border: solid 2px $color-white;
        border-radius: 50%;
        padding: 0;
        margin-right: $unit-size-base;
        background: none;
        cursor: pointer;

        &.is-active {
            background: $color-primary-base;
            border-color: $color-primary-base;
        }
    }

    &__call-to-action {
        position: relative;
        text-align: center;
        z-index: 2;

        @include breakpoint('l') {
            text-align: left;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-top: $unit-size-large*3;
        }

        &-wrapper {
            @include layout;
        }
    }
}

.#{$ns}project-featured-banner-slider {
    position: relative;
    height: 100%;
    width: 100%;
    user-select: none;

    &__slide {
        @include embed-fill();

        opacity: 0;
        background-color: $color-background-dark;
        background-size: cover;
        color: $color-white;
        transition: opacity $transition-timing-default ease-in;

        &.is-active {
            opacity: 1;
            z-index: 1;
        }
    }
}
