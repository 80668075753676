@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Module / Search Form
  */

.#{$ns}search-form {
    $c: &;

    @include embed-fill();
    z-index: 0;

    &__overlay {
        @include embed-fill();

        opacity: 0;
        background: $color-background-lighter;
        pointer-events: none;
        z-index: 2;

        @include breakpoint('l') {
            position: relative;
            z-index: auto;
            opacity: 1;
            pointer-events: all;
            background: none;
            left: unset;
            right: unset;
            margin: 0 auto;
            padding: 0;
        }
    }

    &__fields {
        @include layout();
        @include navbar-height();

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__results {
        display: none;

        &--visible {
            display: block;
        }
    }

    &--with-overlay {
        #{$c} {
            &__overlay {
                z-index: $z-modal;
                opacity: 1;
                pointer-events: all;

                @include breakpoint('l') {
                    z-index: auto;
                }
            }

            &__icon {
                display: none;
            }
        }
    }

    &--with-results {
        #{$c} {
            &__overlay {
                box-shadow: $box-shadow-base;

                @include breakpoint('l') {
                    box-shadow: none;
                }
            }

            &__fields {
                box-shadow: $box-shadow-base;

                @include breakpoint('l') {
                    box-shadow: none;
                }
            }
        }
    }

    &.is-visible {
        z-index: 2;
    }

    &.is-visible &__overlay {
        z-index: $z-modal;
        opacity: 1;
        pointer-events: all;
    }

    &.is-visible &__icon {
        display: none;
    }
}

.#{$ns}search-form-fields {
    $c: &;

    &__field {
        margin: 0 auto;
        max-width: $grid-space-size-xl * 2;
        width: 100%;
        transition: max-width ease-in-out 0.166s;

        @include breakpoint('l') {
            max-width: $grid-column-size-xl * 3 + $grid-space-size-xl * 2;
        }
    }

    &__input {
        padding-left: $unit-size-base*3;
    }

    &__search-button {
        display: block;
        position: absolute;
        height: $unit-size-base*3;
        color: $color-text-light;
        margin: math.div($unit-size-small, 3) 0 math.div($unit-size-small, 3) $unit-size-base;
        padding: $unit-size-base $unit-size-small;
    }

    &__close-button {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        height: $unit-size-base*3;
        color: $color-text-light;
        margin: math.div($unit-size-small, 3) $unit-size-base math.div($unit-size-small, 3) 0;
        padding: $unit-size-base $unit-size-small;
    }

    &--with-close-button {
        #{$c} {
            &__input {
                padding-right: $unit-size-base*3;
            }

            &__close-button {
                display: block;
            }
        }
    }

    &--focused &__field {
        max-width: $grid-column-size-xl * 4 + $grid-space-size-xl * 3;
        width: 100%;
    }
}

.#{$ns}search-form-results {
    @include layout;

    padding-top: $unit-size-base;
    padding-bottom: $unit-size-base;
    background: $color-white;
    border-radius: 2px;
    margin: 0 auto;
    max-width: $grid-column-size-xl * 5 + $grid-space-size-xl * 4;
    min-height: $unit-size-large * 3;

    @include breakpoint('l') {
        width: auto;
        position: absolute;
        top: $unit-size-small * 12;
        left: 50%;
        transform: translateX(-50%);
        min-width: $grid-column-size-xl * 5 + $grid-space-size-xl * 4;
        filter: drop-shadow($drop-shadow-base);

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            box-shadow: $box-shadow-base;
        }

        &::before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(194, 225, 245, 0);
            border-bottom-color: $color-white;
            border-width: ($unit-size-small + math.div($unit-size-small*2, 3));
            margin-left: -1* ($unit-size-small + math.div($unit-size-small*2, 3));
        }
    }

    &__no-results {
        margin: $unit-size-base 0;
        color: $color-text-gray;
        text-align: center;
    }

    &__heading {
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        margin: $unit-size-base 0;

        strong {
            font-weight: $font-weight-light;
            color: $color-text-accent;
        }
    }

    &__group {
        padding: 0 0 $unit-size-base;
    }

    &__more {
        text-align: center;
        font-size: $font-size-tiny;
        margin: 0 0 $unit-size-base;
    }
}

.#{$ns}search-button {
    display: block;
    
    @include breakpoint('l') {
        display: none;
    }
}