// ============================================================================
// Paths
// ============================================================================

$image-path: '../images/' !default;

// ============================================================================
// Namespace
// ============================================================================

$ns: 'gfu-' !default;

// ============================================================================
// Typography
// ============================================================================

$font-family-base: 'Nunito Sans', Helvetica, Arial, sans-serif;
$font-family-decorative: 'Roboto Slab', Helvetica, Arial, sans-serif;
$font-family-soft: 'Nunito Sans', Helvetica, Arial, sans-serif;
$font-family-code: Consolas, monospace;
$font-family-text-security-disc: text-security-disc; // This font is used as an alternative to type="password"

$font-family-icons: 'Font Awesome 6 Pro';
$font-family-brands: 'Font Awesome 6 Brands';

$font-size-nano: 9px;
$font-size-micro: 11px;
$font-size-tiny: 12px;
$font-size-small: 13px;
$font-size-base: 14px;
$font-size-plus: 16px;
$font-size-extra: 18px;
$font-size-big: 20px;
$font-size-super: 24px;
$font-size-huge: 30px;
$font-size-enormous: 40px;
$font-size-gigantic: 48px;
$font-size-immense: 60px;

$line-height-condensed: 1.1;
$line-height-small: 1.3;
$line-height-base: 1.4;
$line-height-big: 1.6;
$line-height-super: 1.8;
$line-height-huge: 2;
$line-height-enormous: 3.2;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-extrabold: 800;

// ============================================================================
// Units
// ============================================================================

$unit-size-half: 3px;
$unit-size-small: 6px;
$unit-size-base: 12px;
$unit-size-large: 24px;

// ============================================================================
// Glyphs
// ============================================================================

$glyph-width-space: 0.4em;

// ============================================================================
// Layout
// ============================================================================

$grid-column-size-xl: $unit-size-base * 6;
$grid-column-size-l: $unit-size-base * 5;

$grid-space-size-xl: $unit-size-small * 6;
$grid-space-size-l: $unit-size-small * 3;
$grid-space-size-m: $unit-size-small * 4;

$layout-padding: 2*$unit-size-small;
$layout-padding-s: 3*$unit-size-small;
$layout-padding-m: $unit-size-large;
$layout-padding-l: 5*$unit-size-small;
$layout-padding-xl: 7*$unit-size-small;

$layout-max-width-l: ($grid-column-size-l * 12) + ($grid-space-size-l * 11);
$layout-max-width-l-narrow: $layout-max-width-l;
$layout-max-width-xl: ($grid-column-size-xl * 12) + ($grid-space-size-xl * 11);
$layout-max-width-xl-narrow: ($grid-column-size-xl * 10) + ($grid-space-size-xl * 9);

$layout-min-width: 312px;

$nav-min-height: $unit-size-small * 13;

// ============================================================================
// Breakpoints
// ============================================================================

$breakpoints: ( s: 480px, m: 768px, l: 992px, xl: $layout-max-width-xl + $layout-padding-xl * 2, xxl: 1600px );

// ============================================================================
// Colors
// ============================================================================

// Common colors
$color-white: #fff;
$color-black: #000;
$color-transparent: rgba(255, 255, 255, 0);   //Fix for iOS

// Main Colors
$color-primary-light: var(--color-primary-light);
$color-primary-base: var(--color-primary-base);
$color-primary-dark: var(--color-primary-dark);
$color-primary-variant: var(--color-primary-variant);
$color-primary-disabled: var(--color-primary-disabled);
$color-primary-disabled-dark: var(--color-primary-disabled-dark);
$color-primary-disabled-light: var(--color-primary-disabled-light);
$color-secondary-disabled: var(--color-secondary-disabled);
$color-dark-coral: var(--color-dark-coral);
$color-dark-coral-10: var(--color-dark-coral-10);

$color-accent-base: var(--color-accent-base);
$color-accent-base-disabled: var(--color-accent-base-disabled);
$color-accent-base-alpha: var(--color-accent-base-alpha);
$color-accent-light: var(--color-accent-light);
$color-accent-dark: var(--color-accent-dark);
$color-accent-lighter: var(--color-accent-lighter);
$color-accent-variant: var(--color-accent-variant);
$color-accent-inverse: var(--color-accent-inverse);

// Text Colors
$color-text-gray: var(--color-text-gray);
$color-text-base: var(--color-text-base);
$color-text-light: var(--color-text-light);
$color-text-lighter: var(--color-text-lighter);
$color-text-dark: var(--color-text-dark);
$color-text-inverse: var(--color-text-inverse);
$color-text-inverse-alpha: var(--color-text-inverse-alpha);
$color-text-primary: var(--color-text-primary);
$color-text-accent: var(--color-text-accent);
$color-text-accent-2: var(--color-text-accent-2);
$color-text-accent-3: var(--color-text-accent-3);
$color-text-accent-4: var(--color-text-accent-4);
$color-text-accent-5: var(--color-text-accent-5);
$color-text-disabled: var(--color-text-disabled);
$color-text-light-gray: var(--color-text-light-gray);

// Page Colors
$color-background-base: #e0e0ea;
$color-background-light: #f2f3f8;

$color-background-lighter: #ffffff;
$color-background-lighter-10: var(--color-text-lighter-10);
$color-background-dark: #353245;
$color-background-darker: #1f1c2d;
$color-background-accent: $color-accent-base;
$color-background-accent-highlight: #a1eccc;
$color-background-disabled: $color-text-disabled;
$color-background-highlighted: #DAF1E6;

// Input Colors:
$color-input-base: #ffffff;
$color-input-border: #e0e0ea;

// Action Colors
$color-error: #d33257;
$color-success: var(--color-accent-base);
$color-success-alt: var(--color-accent-base);
$color-info: #579EF2;
$color-warning: #FFC750;
$color-selected: #e8eaf3;

// Message Colors
$color-message-error: $color-error;
$color-message-success: $color-success;
$color-message-success-alt: $color-success-alt;
$color-message-info: $color-info;
$color-message-warning: $color-warning;

$color-badge-accent-light: #1BAA64;
$color-badge-accent-light-10: rgba($color-badge-accent-light, 0.12);
$color-badge-info-light: #579EF2;
$color-badge-info-light-10: rgba($color-badge-info-light, 0.12);

// Divider Colors
$color-divider-base: $color-text-light;
$color-divider-light: $color-input-border;

// Shadow Colors
$color-shadow-darker: rgba(0, 0, 0, 0.7);
$color-shadow-dark: rgba(0, 0, 0, 0.3);
$color-shadow-base: rgba(0, 0, 0, 0.12);
$color-shadow-light: rgba(0, 0, 0, 0.1);

// Button Colors
$color-button-danger: #D33257;
$color-button-success: #1BAA64;
$color-button-danger-dark: darken($color-button-danger, 10%);
$color-button-success-dark: darken($color-button-success, 10%);

// Border Colors:
$color-border-light: #f2f3f8;

//Skeleton Colors:
$color-skeleton-base: rgba(165, 170, 192, 0.16);
$color-skeleton-light: var(--color-text-light-gray);

// ============================================================================
// Shadows
// ============================================================================

$box-shadow-tight: 0 0 3px $color-shadow-base;
$box-shadow-base: 0 0px 10px 0 $color-shadow-base;
$box-shadow-bottom-base: 0 4px 8px -2px rgba(0, 0, 0, 0.1);

$drop-shadow-base: 0 0 6px $color-shadow-light;
$drop-shadow-tight: 0 2px 1px $color-shadow-base;

$text-shadow-base: 0 2px 1px $color-shadow-darker;
$text-shadow-blurred: 1px 3px 6px rgba($color-text-base, 0.5);

$focus-shadow-base: 2px 2px 4px $color-shadow-base;

// ============================================================================
// Transition
// ============================================================================
$transition-timing-default: 0.666s;
$transition-timing-quick: 0.1s;

$transition-default: ease-in-out $transition-timing-default;
$transition-slow: cubic-bezier(0.25, 0.1, 0.25, 1) 0.25s;
$transition-quick: ease-in-out $transition-timing-quick;

$transition-opacity: opacity $transition-quick;
$transition-background-color: background-color $transition-default;

// ============================================================================
// Z-index
// ============================================================================

$z-progress-overlay: 9998;
$z-modal: 9999;
$z-floating-notification: 10000;

$z-header: 50;
$z-admin-nav: 60;
$z-sidenav: 55;
$z-admin: 50;


$z-mini-wizard: 47;
$z-project-nav: $z-mini-wizard + 1;
$z-layout-bar: $z-project-nav + 1;

$z-nav: 10;
$z-front: 20;

$z-base: 1;
$z-hidden: -1;

// ============================================================================
// Radiuses
// ============================================================================

$common-border-radius: 9px;
$container-border-radius: 9px;
$container-border-radius-tight: 9px;
$input-field-border-radius: 4px;
$badge-border-radius: 4px;
$block-border-radius: 6px;

// ============================================================================
// Nav bars
// ============================================================================

$navbar-height-s: $unit-size-small * 9;
$navbar-height-m: $unit-size-small * 13;

$navbar-height: $unit-size-small * 13; // @Todo: check and replace responsive
$navbar-line-height: $unit-size-small * 9;

$sidenav-height: calc(100vh - #{$navbar-height-m});

$nav-dropdown-max-height: 11*$unit-size-small*6;
$nav-dropdown-min-width: $unit-size-large * 7;

$sidenav-width: $unit-size-base * 31;
$sidenav-width-s: $unit-size-small * 43;

$navbar-bg-color-main: $color-background-darker;
$navbar-bg-color-secondary: $color-background-dark;
$navbar-bg-color-tertiary: $color-background-darker;
$navbar-bg-color-hovered: #1E1B2C4D;
$sidenav-bg-color-main: $color-white;

$navbar-text-color-main: $color-white;
$navbar-text-color-secondary: $color-text-lighter;

// ============================================================================
// Layout
// ============================================================================

$layout-width-narrow: 1074px;
$layout-width-extranarrow: 888px;

// ============================================================================
// Tooltip
// ============================================================================

$tooltip-min-width: $grid-column-size-xl * 3 + $grid-space-size-xl * 2; // 276px
$tooltip-max-width: $grid-column-size-xl * 4; // 288px
