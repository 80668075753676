@import 'variables';
@import 'mixins';

/***
  * Component / Labels
  */

.#{$ns}label {
    display: inline-flex;
    padding: 0 $unit-size-small;
    font-size: $font-size-nano;
    text-transform: uppercase;
    border-radius: $input-field-border-radius;
    line-height: $unit-size-small * 3;
    font-weight: $font-weight-bold;

    &--badge {
        border-radius: 50%;
        width: $unit-size-small * 3;
        height: $unit-size-small * 3;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    &--empty {
        background-color: currentColor;
        border: 1px solid $color-input-border;
        color: $color-white;
    }

    &--primary {
        background-color: $color-primary-base;
        color: $color-white;
    }

    &--primary-light {
        background-color: $color-primary-light;
        color: $color-primary-base;
    }

    &--accent {
        background-color: $color-accent-base;
        color: $color-white;
    }

    &--accent-light {
        background-color: $color-badge-accent-light-10;
        color: $color-badge-accent-light;
    }

    &--success {
        background-color: $color-success;
        color: $color-white;
    }

    &--error {
        background-color: $color-error;
        color: $color-white;
    }

    &--info {
        background-color: $color-info;
        color: $color-white;
    }

    &--info-light {
        background-color: $color-badge-info-light-10;
        color: $color-badge-info-light;
    }

    &--label-dark {
        background-color: $color-dark-coral-10;
        color: $color-dark-coral;
    }

    &--light {
        background-color: $color-background-light;
    }

    &--disabled {
        background-color: $color-primary-disabled;
        color: $color-white;
    }

    &--warning {
        color: $color-black;
        background-color: $color-warning;
        border: none;
    }

    &--suggestion {
        color: $color-primary-base;
        border: none;
        background-color: $color-background-light;

        &:hover,
        &:focus {
            background-color: $color-white;
        }
    }

    &--default {
        background-color: $color-primary-base;
        color: $color-white;
    }

    &--banned {
        background-color: $color-error;
        color: $color-white;
        border-radius: 50%;
        font-size: $font-size-base;
        line-height: $font-size-base;
        padding: $unit-size-small;
    }

    &--achievement {
        align-items: center;
        width: fit-content;
        gap: $unit-size-small * 0.5;
        padding: $unit-size-small * 0.5 $unit-size-small;
        cursor: default;
        max-height: $unit-size-small * 3;
        position: relative;

        &:before {
            z-index: -1;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-white;
            border-radius: inherit;
        }
    }
}

/***
  * Component / Labels
  */

.#{$ns}labels {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);

    &--bottom {
        top: auto;
        bottom: 0;
        transform: translateY(50%);
    }
}
