@import 'variables';
@import 'mixins';

/***
  * Module / Header
  */
$header-dropdown-arrow-size: 10px;

$header-icon-item-width: $unit-size-large * 2;
$header-icon-item-width-l: $unit-size-large * 3;

.#{$ns}header {
    position: relative;
    z-index: $z-header;

    @include breakpoint('l') {
        box-shadow: none;
        padding-top: 0;
    }

    &__navbar {
        @include layout('full');

        display: flex;
        justify-content: space-between;
        box-shadow: $box-shadow-bottom-base;
    }

    &__layout {
        position: sticky;
        top: 0;
        z-index: $z-header;
    }

    &.is-sticky {
        position: sticky;
        top: 0;
    }

    &__actions {
        position: relative;
        z-index: 1;
        margin-right: -$unit-size-base;

        @include breakpoint('l') {
            z-index: 3;
        }
    }
}

/***
  * Module / Header Logo
  */

.#{$ns}header-logo {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 1;
    height: 100%;

    @include breakpoint('l') {
        z-index: 3;
    }

    &__icon {
        display: block;
        height: $unit-size-small * 5;

        @include breakpoint('m') {
            height: auto;
        }

        @include breakpoint('l') {
            display: none;
        }
    }

    &__full {
        display: none;

        @include breakpoint('l') {
            display: block;
        }
    }
}

/***
  * Module / Header Mobile
  */

.#{$ns}header-mobile {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-background-darker;
    z-index: $z-modal;
    color: $color-text-inverse;

    &__logo {
        @include layout('full');
        @include navbar-height();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: transparent;
    }

    &__close {
        @include navbar-height();
        @include navbar-height('line-height');

        position: absolute;
        top: 0;
        right: 0;
        padding: 0 $unit-size-base;
        font-size: $unit-size-small * 6;
        background-color: transparent;
        border: none;
        color: inherit;
        outline: none;
        z-index: 3;

        @include breakpoint('m') {
            font-size: $unit-size-small * 9;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        flex-grow: 1;
        height: 100%;
        // Fix for modals
        z-index: 2;
    }

    &__layout {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }

    &__dropdown {
        @include navbar-height(min-height);

        position: relative;
        display: flex;
        gap: $unit-size-base;
        padding: $unit-size-base 0;
        text-align: center;
        margin: 0 auto;
    }

    &__content {
        @include layout;

        display: flex;
        flex-grow: 1;
        overflow: visible;
        justify-content: center;
        flex-direction: column;
        z-index: $z-base + 1;
        font-family: $font-family-soft;
        color: $color-white;
        line-height: $line-height-base;
        width: 70%;

        @include breakpoint('s') {
            width: 50%;
        }

        &--top {
            justify-content: flex-start;
            margin-top: $unit-size-base;

            @include breakpoint('s') {
                margin-top: $unit-size-base;
            }
        }
    }

    &__bottom {
        @include layout;

        line-height: $line-height-base;
        z-index: $z-base;
        margin-bottom: $unit-size-large * 5;
        width: 70%;

        @include breakpoint('s') {
            width: 50%;
            margin-bottom: $unit-size-base * 5;
        }
    }

    &__title {
        font-size: $font-size-plus;
        font-weight: $font-weight-bold;
    }

    &__box {
        width: 100%;
        height: $unit-size-large*5;
        border-radius: 2px;
        background-color: $color-background-dark;
        border: 2px solid $color-primary-base;
        color: $color-white;

        &-title {
            padding-top: 3*$unit-size-base;
            font-size: $font-size-big;
            font-weight: $font-weight-bold;
        }
    }

    &__box-group {
        width: 100%;
        border-radius: 2px;
        background-color: $color-background-dark;
        border: 2px solid $color-primary-base;
        border-bottom: none;
        text-transform: capitalize;
        color: $color-white;

        &-collapsible {
            max-height: 0;
            transition: $transition-opacity;
            opacity: 0;
            pointer-events: none;
            overflow-y: hidden;

            &--is-open {
                max-height: none;
                opacity: 1;
                pointer-events: auto;
                overflow-y: auto;
            }
        }
    }

    &__box-group-item {
        position: relative;
        width: 100%;
        min-height: 3*$unit-size-small;
        padding: $unit-size-base;
        border-bottom: 2px solid $color-primary-base;
        font-weight: $font-weight-bold;
        line-height: $line-height-condensed;

        &--dark {
            background-color: $color-background-darker;
        }

        &--selected {
            background-color: $color-primary-base;
            border: 2px solid $color-primary-base;
        }

        &--disabled {
            opacity: 0.5;
        }

        &--has-icon {
            padding: $unit-size-base $unit-size-large;
        }

        &-icon {
            top: 50%;
            transform: translateY(-50%);
            right: $unit-size-small + $unit-size-small*0.5;
            display: inline-block;
            position: absolute;

            &::before {
                @include fa-light;

                font-size: $font-size-big;
                content: '\f054';
                color: $color-text-accent;
                margin-bottom: $unit-size-base;
                text-align: center;
            }

            &--expanded {
                right: $unit-size-small;

                &::before {
                    content: '\f078';
                }
            }
        }
    }
}





/***
  * Module / Header / Animations
  */

.#{$ns}header-shake::after {
    display: inline-block;
    animation: shake 0.25s ease-in-out infinite;
}
