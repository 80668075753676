@use "sass:math";

@import 'variables';
@import 'mixins';

.#{$ns}signing-page {
    padding-top: $unit-size-large;
    padding-bottom: $unit-size-large;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint('m') {
        padding-top: $unit-size-large + $unit-size-base;
        padding-bottom: $unit-size-large + $unit-size-base;
        flex-direction: row;
        justify-content: space-between;
    }

    &__intro {
        display: flex;
        flex-direction: column;

        @include breakpoint('m') {
            align-items: center;
        }
    }

    &__benefits {
        padding-top: $unit-size-large;

        @include breakpoint('m') {
            padding-top: 0;
            padding-bottom: ($unit-size-large * 2) + ($unit-size-small * 3);
        }
    }

    &__image {
        display: none;

        @include breakpoint('m') {
            display: block;
            width: 100%;
        }
    }
}

//external login providers buttons

.#{$ns}btn-signing {
    padding: $unit-size-base math.div($unit-size-large * 2, 3);
    border-radius: $container-border-radius-tight;
    background-color: $color-white;
    margin-bottom: math.div($unit-size-base + $unit-size-large, 3);
    width: 100%;
    box-shadow: $box-shadow-base;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-transform: initial;
    font-size: $font-size-base;
    color: $color-text-base;
    border: none;

    &:hover {
        border: inherit;
        background-color: $color-white;
        color: inherit;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &:focus,
    &:active,
    &:hover {
        outline: none;
        text-decoration: none;
    }
}
