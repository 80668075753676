@import 'variables';
@import 'mixins';

/***
  * Component / Category box
  */


.#{$ns}category-box {
    position: relative;
    overflow: hidden;
    box-shadow: $box-shadow-base;
    &:hover {
        text-decoration: none;
    }

    &--default {
      background-color: $color-white;
    }

    &--linear {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: linear-gradient(93deg, $color-primary-dark 1.75%, #3C3C40 98.74%);
    }
  }
}

.#{$ns}category-box-icon {
    color: $color-text-dark;
    
    &--linear {
      position: absolute;
      right: $unit-size-small;
      bottom: -$unit-size-small / 2;
      z-index: -1;
    }
}