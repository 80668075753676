@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Table
  */

$table-main-color: $color-background-light;
$table-secondary-color: $color-white;
$table-hover-color: $color-background-light;
$table-text-color: $color-text-gray;
$table-border-width: 1px;
$table-row-height: $unit-size-small * 9;
$table-border: $table-border-width solid $color-divider-light;

.#{$ns}table {
    font-family: $font-family-base;
    color: $color-text-base;
    width: 100%;
    overflow: visible;
    background-color: $table-secondary-color;
    font-size: $font-size-tiny;
    line-height: 1.75;
    display: flex;
    flex-direction: column;
    min-height: $unit-size-small * 9;

    &--nested {
        padding: 0 0 $unit-size-base $unit-size-base;

        @include breakpoint('s') {
            padding-left: $unit-size-base * 7;
        }
    }

    &--scrollable {
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: $unit-size-base;
        margin-top: $unit-size-small * 5;

        .#{$ns}table__body {
            overflow: auto;
            min-height: unset;
        }

        .#{$ns}table__prefix {
            margin-top: 0;
        }
    }

    &__prefix,
    &__suffix {
        overflow: hidden;
        height: auto;
        min-height: $unit-size-small * 5;
        margin-top: $unit-size-small * 5;
        font-size: $font-size-base;

        &--expanded {
            border: 2px solid $table-main-color;
            padding: $unit-size-small * 3;
            overflow: visible;
            min-height: inherit;
        }
    }

    &__prefix {
        margin-bottom: $unit-size-large * 2;
        border-top-left-radius: $common-border-radius;
        border-top-right-radius: $common-border-radius;

        &--small {
            margin-bottom: $unit-size-small * 3;
            margin-top: 0;
        }

        &--expanded {
            flex: 0 0 auto;
            margin-bottom: 0;
            border-bottom: none;
        }
    }

    &__suffix {
        border-bottom-left-radius: $common-border-radius;
        border-bottom-right-radius: $common-border-radius;

        &--expanded {
            margin-top: 0;
            border-top: none;
        }
    }

    &__head {
        position: relative;
        font-weight: $font-weight-extrabold;
        font-size: $font-size-base;
        line-height: $line-height-base;
        text-transform: capitalize;
        background-color: $table-main-color;
        border-bottom: 2px solid $color-white;
        border-radius: $unit-size-small * 0.5;

        &--no-fx {
            text-transform: none;
        }
    }

    &__body {
        color: $table-text-color;
        flex: 1 1 auto;
        min-height: $unit-size-small * 9;
        overflow-y: auto;
        -ms-overflow-style: auto;
        overflow-x: hidden;
        outline: none;

        @include breakpoint('s') {
            >.#{$ns}table__row:last-child {
                border-bottom: ($unit-size-small * 0.5) solid $table-main-color;
            }

            .#{$ns}table__row--drag-down:last-child,
            .#{$ns}table__row.is-drop-target-bottom:last-child {
                border-bottom-color: $color-text-accent;
            }
        }

        &--no-underline {
            border-bottom: none;

            >.#{$ns}table__row:last-child {
                border-bottom: none;
            }
        }

        .#{$ns}table__row {
            min-height: $table-row-height;

            &--tiny {
                min-height: $unit-size-small * 4;
            }
        }

        +.#{$ns}table__footer {
            border-bottom: none;
        }

        &--no-scroll {
            overflow: visible;
        }

        &--underlined {
            border-bottom: math.div($unit-size-small, 3) solid $table-main-color;

            >.#{$ns}table__row:last-child {
                border-bottom: none;
            }
        }
    }

    &__custom-items {
        margin-top: $unit-size-small;

        .#{$ns}table__row {
            margin-bottom: $unit-size-small * 0.5;
            background-color: $table-main-color !important;
        }
    }

    &__footer {
        font-weight: bold;
        border-bottom: ($unit-size-small * 0.5) solid $table-main-color;

        &--dark {
            background-color: $table-main-color !important;
        }

        &--light {
            background-color: $table-secondary-color !important;
        }

        &--lightweight {
            font-weight: $font-weight-normal;
            color: $color-text-gray;
        }

        .#{$ns}table__row--striped:nth-of-type(even) {
            background-color: $table-secondary-color;
            border-color: $table-secondary-color;
        }

        .#{$ns}table__row--striped:nth-of-type(odd) {
            background-color: $table-main-color;
            border-color: $table-main-color;

            +.#{$ns}table--nested {
                background-color: $table-main-color;
                border-color: $table-main-color;

                .#{$ns}table__row {
                    border: $table-border;
                }
            }
        }

        .#{$ns}table__cell--title {
            text-align: left;

            @include breakpoint('s') {
                text-align: right;
            }
        }
    }

    &__row-group {
        flex-direction: column;
        display: flex;
        margin: $unit-size-small * 3 0;
        background-color: $table-secondary-color;
        padding: ($unit-size-small * 0.5) * 3;
        border-radius: $common-border-radius;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.141);
    }

    &__row-count {
        position: absolute;
        right: 0;
        top: - $unit-size-small * 3;
        font-size: $font-size-micro;
        font-weight: $font-weight-light;
        color: $color-text-light;
        text-transform: lowercase;
    }

    &__row {
        flex-direction: row;
        display: flex;
        align-items: center;
        padding: $unit-size-base - $unit-size-small * 0.5;
        border-radius: $unit-size-small * 0.5;
        border: solid transparent;
        border-width: 3px 0;

        &--total-highlighted {
            font-weight: $font-weight-extrabold;
            font-size: $font-size-base;
            color: $color-text-dark;
        }

        &--highlighted {
            background-color: $color-accent-light;
        }

        &--accented {
            color: $color-accent-base;
        }

        &--error {
            color: $color-message-error;
        }

        &--action {
            font-size: $font-size-base;
        }

        &--centered {
            justify-content: center;
        }

        &--clickable {
            &:hover {
                background-color: $table-hover-color;
                border-color: $table-hover-color;
                cursor: pointer;
            }
        }

        &--compact {
            padding-top: $unit-size-small*0.5;
            padding-bottom: $unit-size-small*0.5;
        }

        &--dark {
            background-color: $table-main-color;
            border-color: $table-main-color;
        }

        &--divider {
            border-bottom: math.div($unit-size-small, 3) solid $table-main-color;
        }

        &--nested {
            margin-right: $unit-size-small * 3;
        }

        &--selected {
            background-color: $table-hover-color;
            border-color: $table-hover-color;
            font-weight: $font-weight-bold;

            &:hover {
                cursor: pointer;
            }
        }

        &--short {
            padding-top: $unit-size-small * 0.5;
            padding-bottom: $unit-size-small * 0.5;
        }

        &--single {
            +.#{$ns}table__row--single {
                margin-top: $unit-size-base;
            }
        }

        &--striped:nth-of-type(even) {
            background-color: $table-main-color;
            border-color: $table-main-color;

            +.#{$ns}table--nested {
                background-color: $table-main-color;
                border-color: $table-main-color;

                .#{$ns}table__row {
                    border: $table-border;
                }
            }
        }

        &--outline {
            border: $table-border;

            @include breakpoint('s') {
                border: none;
            }
        }

        &--striped:nth-of-type(odd) {
            background-color: $table-secondary-color;
            border-color: $table-secondary-color;
        }

        &--tiny {
            padding-top: 0;
            padding-bottom: 0;
            border-width: 0;
        }

        &--drag-up,
        &--drag-down {
            border-radius: 0;

            * {
                pointer-events: none;
            }
        }

        &--drag-up,
        &.is-drop-target-top {
            border-top-color: $color-text-accent;
        }

        &--drag-down,
        &.is-drop-target-bottom {
            border-bottom-color: $color-text-accent;
        }

        &--fill {
            height: 100%;
        }

        &--top-aligned {
            align-items: flex-start;
        }
    }

    &__cell {
        $c: &;

        padding: $unit-size-small;
        flex-grow: 0;
        text-overflow: ellipsis;
        overflow: hidden;

        pre {
            margin: 0;

            @include pre-wrap;
        }

        &-row {
            flex-direction: column;
            display: flex;
            width: 100%;

            @include breakpoint(s) {
                flex-direction: row;
                align-items: center;
            }
        }

        &::before {
            float: left;
            content: attr(data-text);
        }

        &--grow {
            flex-grow: 1;
        }

        &--centered {
            align-self: center;
        }

        &--no-results {
            color: $color-text-gray;
            font-size: $font-size-tiny;
            flex-grow: 1;
            text-align: center;
            min-height: $unit-size-large * 9;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &--create {
            font-size: $font-size-tiny;
            flex-grow: 1;
            text-align: center;
        }

        &--scroll {
            font-size: $font-size-tiny;
            flex-grow: 1;
            text-align: center;
        }

        &::before {
            display: none;
        }

        &--light {
            color: $color-text-light;
        }

        &--good {
            border-color: $color-success;
            color: $color-message-success;
        }

        &--bad {
            border-color: $color-error;
            color: $color-message-error;
        }

        &--info {
            border-color: $color-primary-base;
            color: $color-text-primary;
        }

        &--short {
            padding-top: 0;
            padding-bottom: 0;
        }

        &--toggle {
            justify-self: flex-start;
            margin-right: auto;
            width: $unit-size-small * 5;
            text-overflow: unset;
        }

        &--image {
            justify-self: flex-start;
            margin-right: auto;
            height: $unit-size-large * 2 + $unit-size-small + 2px; //Img + padding + border
            min-width: $unit-size-large * 2 + $unit-size-small + 2px;
            width: $unit-size-large * 2 + $unit-size-small + 2px;
            padding: 0;
            overflow: visible;

            &-big {
                height: $unit-size-large * 4;
                min-width: $unit-size-large * 4;
                width: $unit-size-large * 4;

                .#{$ns}table__thumb {
                    height: $unit-size-large * 4;
                    min-width: $unit-size-large * 4;
                    width: $unit-size-large * 4;
                }
            }
        }

        &--drag {
            justify-self: flex-start;
            width: $unit-size-small * 5;
            text-overflow: unset;

            a {
                color: $color-text-lighter;
                font-size: $font-size-plus;
            }

            &:hover a {
                text-decoration: none;
                cursor: grab;
            }
        }

        &--actions {
            display: flex;
            justify-self: flex-end;
            margin-left: auto;
            min-width: $unit-size-small * 7;
            overflow: visible;
            position: relative;
            text-align: right;
            gap: $unit-size-small;

            &-double {
                min-width: $unit-size-small * 13;
            }

            &-triple {
                min-width: $unit-size-small * 19;
            }
        }

        &--stretch {
            align-self: stretch;
        }

        &--with-overflow {
            overflow: visible;
        }

        .#{$ns}field__append-wrapper .#{$ns}field__error--tooltip {
            // Explicit number to fix tooltip position without huge modification on global field-error.
            right: 44px;
        }
    }

    //TODO: this is a black magic and it should be rewritten
    &__cell-product-option {
        @include breakpoint(s) {
            flex-basis: calc(75% - 10vw); // basis offset needs to be proportional to viewport size 10.5vw ~= (indent + paddings / 480px) * 75%

            &--non-editable {
                flex-basis: calc(80% - 11vw);
            }
        }

        @include breakpoint(m) {
            flex-basis: calc(60% - 5vw); // basis offset needs to be proportional to viewport size 5.5vw ~= (indent + paddings / 768px) * 60%

            &--non-editable {
                flex-basis: calc(65% - 6vw);
            }
        }

        @include breakpoint(l) {
            flex-basis: calc(60% - #{4*$unit-size-base + $table-border-width});

            &--non-editable {
                flex-basis: calc(65% - #{5*$unit-size-base});
            }
        }

        @include breakpoint(xl) {
            &--non-editable {
                flex-basis: calc(65% - #{5*$unit-size-base + 2*$table-border-width});
            }
        }
    }

    &__cell-collapsible {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &--expanded {
            display: block;
        }
    }

    &__cell-collapsible-icon {
        position: relative;
        height: 3em;
        cursor: pointer;
        align-self: flex-start;

        @include collapsible-icon() {
            transform: translateY(-50%) rotate(0deg);
        }
    }

    &__thumb {
        background-color: $table-secondary-color;
        padding: $unit-size-small*0.5;
        border: $table-border;
        border-radius: $unit-size-small;
        overflow: hidden;
        width: 4*$unit-size-base + 2*$unit-size-small*0.5 + 2*$table-border-width;
        height: 4*$unit-size-base + 2*$unit-size-small*0.5 + 2*$table-border-width;
    }

    &__collapsible {
        &--hidden {
            display: none;
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $unit-size-small * 5;
        height: $unit-size-small * 5;
        border-radius: 50%;
        background-color: $color-background-light;
        font-size: $font-size-plus;
        color: $color-text-lighter;

        &--success {
            background-color: $color-accent-base;
            color: $color-white;
        }
    }

    &__text {
        &--strong {
            font-weight: $font-weight-extrabold;
            color: $color-text-base;
        }

        &--small {
            font-size: $font-size-small;
        }

        &--big {
            font-size: $font-size-plus;
        }
    }

    &__amount {
        &-btn {
            display: none;
            z-index: $z-base + 1;
            position: absolute;
            right: $unit-size-small * 0.5;
            font-size: $font-size-micro;
            height: $unit-size-small * 3;
            width: $unit-size-small * 3;

            &--plus {
                top: $unit-size-base + math.div($unit-size-small, 3);
            }

            &--minus {
                top: $unit-size-base * 3 + math.div(- $unit-size-small, 3);
            }

            @include breakpoint('l') {
                display: block;
            }
        }

        &-error {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            text-align: center;

            &::after {
                content: '\f071';
                font-family: $font-family-icons;
                font-size: $font-size-plus;
                color: $color-message-error;
            }

            &-wrapper {
                position: relative;
                width: 100%;
                display: flex;

                &--inline {
                    width: unset;
                    float: left;
                    left: 50%;

                    .#{$ns}table__amount-error {
                        left: $unit-size-large;
                        top: - $unit-size-small * 0.5;
                    }
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: $unit-size-small*0.5;
        right: $unit-size-small;
        cursor: pointer;
        font-weight: $font-weight-bold;
        z-index: 1;
    }

    &-actions {
        position: relative;
        overflow: visible;
        display: inline-block;
        flex: initial;

        &__wrapper {
            position: relative;
        }
    }

    &-card {
        border-radius: $common-border-radius;
        background-color: $table-main-color;
        padding: $unit-size-small * 3;

        &:not(:first-of-type) {
            margin-top: $unit-size-base;
        }
    }

    &__dropdown {
        opacity: 1;
        z-index: 1;
        pointer-events: all;
        position: absolute;
        right: -4px;
        top: $unit-size-base*3;
        transition: $transition-opacity;
        min-width: $unit-size-large*10;
    }

    &__button {
        &--selected {
            color: $color-white;
            background-color: $color-primary-base;
            border-color: $color-primary-base;
        }
    }

    &__align-top {
        $base-margin: (
            $unit-size-small * 0.5
        );

    align-self: flex-start;
    margin-top: $base-margin;

    div {
        margin-top: -$base-margin;
    }

    &--extra {
        padding-top: ($unit-size-small * 4) + $base-margin;
    }
}

&__options {
    align-self: flex-start;
}

&__option-action {
    align-self: flex-start;
    margin-top: $unit-size-small * 3;
    padding: $unit-size-small * 0.5 0 $unit-size-small * 0.5 $unit-size-small * 0.5;
    min-width: $unit-size-small * 5.5;

    @include breakpoint('m') {
        margin-top: $unit-size-small * 4;
        padding-left: 0;
    }

    &--large {
        margin-top: $unit-size-small * 6;
    }
}

.#{$ns}empty-state {
    min-height: $table-row-height * 4;
}
}

.#{$ns}filters {
    position: relative;
    background: $table-main-color;
    padding: $unit-size-small * 3;
    overflow: visible;
    border-radius: $container-border-radius-tight;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    @include breakpoint(s) {
        padding: $unit-size-small * 3;
    }

    &--collapsed {
        padding: 0;
    }

    &__body {
        padding: $unit-size-base 0;
    }

    &__caption {
        font-size: $font-size-plus;
        cursor: pointer;
        padding: $unit-size-small * 3;
        text-align: left;

        &--expanded {
            padding: 0;
        }
    }

    &__caption-icon {
        color: $color-accent-base;
        margin-right: $unit-size-small;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--fixed {
            top: 0;
            right: 0;
            position: absolute;
            padding: $unit-size-small * 3;
        }
    }

    &__list {
        position: relative;
        display: inline-block;
    }

    &__reset {
        margin-right: $unit-size-base;
        display: inline-block;
    }
}

.#{$ns}pager {
    margin-bottom: $unit-size-base;
    font-size: $font-size-small;
    text-align: right;
}

/***
  * Component / Table meta
  */

.#{$ns}table-meta {
    display: flex;
    flex-direction: column;
    padding-top: $unit-size-base;
    justify-content: stretch;

    &.is-expanded {
        padding-bottom: $unit-size-small * 2;

        @include breakpoint('l') {
            padding-bottom: $unit-size-small * 5;
        }
    }

    @include breakpoint('l') {
        flex-direction: row;
    }

    &__labels {
        position: absolute;
        top: 0;
        left: $unit-size-small * 5;
        transform: translateY(-($unit-size-small * 2));
    }

    &__items {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include breakpoint('l') {
            justify-content: space-between;
            flex-direction: row;
        }
    }

    &__actions {
        position: absolute;
        top: -$unit-size-small * 3;
        right: $unit-size-small + ($unit-size-small * 0.5);
        min-width: $unit-size-small * 7;
        text-align: right;

        @include breakpoint('l') {
            position: relative;
            top: unset;
            right: unset;
            order: 3;
        }
    }

    &__more {
        text-align: center;
        flex-grow: 0;
        flex-shrink: 0;

        @include breakpoint('l') {
            order: 1;
            margin-right: $unit-size-small * 3;
        }
    }

    &__balloon::before {
        margin-right: 3px; // hack to center angle from balloon
    }
}

.#{$ns}table-meta-more {
    &:hover {
        text-decoration: none;
    }

    &__icon {
        color: $color-accent-base;
        margin-right: $unit-size-small;

        @include breakpoint(l) {
            font-size: $font-size-big;
        }
    }

    &__label {
        @include breakpoint(l) {
            display: none;
        }
    }
}

/***
  * Component / Table definition
  */

.#{$ns}table-definition {
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: $unit-size-small * 3;

    @include breakpoint('l') {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
    }

    &__label {
        font-weight: normal;
        text-transform: uppercase;
        color: $color-text-light;
    }

    &__value {
        display: block;
        margin: 0;
        text-align: left;
        font-weight: $font-weight-extrabold;
        color: $color-text-base;
    }

    &--col-2 {
        @include breakpoint('l') {
            width: $grid-column-size-l * 1;
        }
    }

    &--col-2 {
        @include breakpoint('l') {
            width: $grid-column-size-l * 2;
        }
    }

    &--col-3 {
        @include breakpoint('l') {
            width: $grid-column-size-l * 3;
        }
    }

    &--col-4 {
        @include breakpoint('l') {
            width: $grid-column-size-l * 4;
        }
    }
}

/***
  * Module / Table with chart
  */

.#{$ns}table-with-chart {
    &__table {
        max-height: $unit-size-small * 84;
    }
}