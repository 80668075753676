@use "sass:math";

@import 'variables';
@import 'mixins';

$comment-padding-small: $unit-size-small * 3;
$comment-padding: $unit-size-small * 5;
$bubble-size: $unit-size-small * 7;
$comment-icon-font-size: $font-size-extra;
$comment-streams-padding: $unit-size-small * 5;
$input-default-height: $unit-size-small*7;

.#{$ns}comments-mobile-menu {
    &__content {
        text-align: center;
        margin: unset;
        width: 100% !important;

        @include breakpoint('s') {
            // 8/12 width
            width: 66% !important;
        }
    }
}

.#{$ns}comment-streams {
    &__box {
        @include navbar-height(top, $unit-size-base * 2);

        position: sticky;
        background-color: $color-white;
        padding: 0 $unit-size-small * 5;
    }

    &__search-input-container {
        padding-top: $comment-streams-padding;

        &--active > input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
        }
    }

    &__search-input {
        &--alt {
            color: $color-background-base !important;
            background-color: transparent !important;
        }
    }

    &__tags {
        margin-top: $unit-size-small * 3;
        padding-bottom: $unit-size-small * 4;
    }

    &__paragraph {
        text-align: center;
        font-size: $font-size-tiny;
        color: $color-text-gray;
    }

    &__button {
        font-size: $font-size-tiny;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        max-width: 100%;

        &--alt {
            background-color: transparent;
            border-color: $color-white;
            color: $color-white;
        }
    }

    &__suggestions {
        background-color: $color-background-lighter;
        border-color: $color-input-border;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        position: absolute;
        z-index: $z-front;
        margin: 0 auto;
        left: 0;
        right: 0;
        border-top: none;
        border-bottom-left-radius: $unit-size-small * 0.5;
        border-bottom-right-radius: $unit-size-small * 0.5;

        &-wrapper {
            position: relative;
            padding: 0;
            margin: 0;
        }

        &--no-matches {
            user-select: none;
            cursor: default;
            pointer-events: none;
        }

        ul {
            list-style-type: none;
            margin: 0;
            text-align: left;

            li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                width: 100%;
                font-size: $font-size-base;
                color: $color-text-gray;
                padding: $unit-size-small $unit-size-base;
            }
        }

        &--alt {
            ul {
                li {
                    color: $color-background-base;
                    background-color: $color-background-darker;
                }
            }
        }
    }
}

/**
 * Comments View
 */

.#{$ns}comments-view {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 320px;

    @include breakpoint('l') {
        flex-direction: row;
    }

    &__main {
        flex-grow: 1;
    }

    &__side {
        @include breakpoint('l') {
            width: 30%;
            flex-shrink: 0;
            margin-left: $unit-size-small * 6;
        }
    }

    &__title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include breakpoint('l') {
            flex-direction: row;
            justify-content: start;
        }
    }
}

.#{$ns}comments-view-content {
    &--compact {
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: $unit-size-small * 90;
    }
}

/**
 * Comments Item
 */

.#{$ns}comment-item {
    &--hidden &__content {
        opacity: 0.25;
    }

    &__content {
        padding-top: $unit-size-small;
        padding-bottom: $unit-size-small;
        margin-bottom: $unit-size-small;
        white-space: pre-wrap;

        @include wordbreak;
    }

    &__tag {
        display: inline-block;
        color: $color-primary-base;
        font-weight: $font-weight-extrabold;
        line-height: $font-size-base;
        cursor: pointer;
    }
}

/**
 * Comments Reply
 */

.#{$ns}comment-reply {
    height: $unit-size-base * 2;
}

/**
 * Comments Actions
 */

.#{$ns}comment-item-actions {
    position: relative;

    &__btn {
        height: $unit-size-base * 2;
        width: $unit-size-base * 2;
        border-radius: $unit-size-base * 2;
        line-height: $unit-size-base * 2;
        padding: 0;
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, $unit-size-small);
    }
}

/**
 * Comments Field
 */

.#{$ns}comment-field {
    $editor-line-height: 20px;

    &__wrap {
        position: relative;
        padding: $unit-size-small;
        border: 1px solid $color-input-border;
        border-radius: $input-field-border-radius;
        min-height: $unit-size-small * 11;

        &.is-focused {
            box-shadow: $focus-shadow-base;
        }
    }

    &__avatar {
        margin: 1px $unit-size-small 2px 0; // Space for outline shadow.
    }

    &__author {
        display: flex;
    }

    &__content {
        line-height: $editor-line-height;
    }

    &__editor {
        line-height: $editor-line-height;
        border: none;
        outline: none;
        outline-color: transparent;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: $editor-line-height;
        max-height: $editor-line-height * 5;
        caret-color: $color-accent-base;

        &[placeholder]:empty::before {
            cursor: text;
            content: attr(placeholder);
            color: $color-text-light;
        }

        &[placeholder]:empty:focus::before {
            content: '';
            color: $color-text-light;
        }

        @include wordbreak;

        @include breakpoint('l') {
            max-height: $editor-line-height * 10;
        }
    }

    // Hack to override base styles, @todo: change base styles for focus.
    & &__editor[contenteditable][placeholder][class] {
        &:active,
        &:focus {
            box-shadow: none;
        }
    }

    &__actions {
        margin-top: $unit-size-small * 2;
        text-align: end;
    }
}

/**
 * Comments filters
 */

.#{$ns}comment-filters {
    position: relative;
    justify-content: center;
    margin: 0 0 $unit-size-base;
    padding: $unit-size-small 0;

    @include breakpoint('l') {
        padding: 0;
        margin-bottom: $unit-size-small * 5;
    }
}

/**
 * Comments button badge
 * @todo: create badge element
 */
.#{$ns}comment-badge {
    color: $color-white;
    background-color: $color-accent-base;
    border-radius: $unit-size-base;
    text-align: center;
    font-weight: $font-weight-bold;
    padding: $unit-size-small * 0.5 $unit-size-small;
    min-width: $unit-size-base;
    height: $unit-size-small * 3;
    display: inline-block;
}

// Wrapper for user labels
.#{$ns}comments-labels {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $unit-size-small * 0.5;
    width: fit-content;
    margin-top: $unit-size-small * 0.5;
    line-height: 1;

    @include breakpoint('l') {
        gap: ($unit-size-small + $unit-size-base) * 0.5;
    }
}

// Comments Moderation
.#{$ns}reported-comment {
    display: flex;
    align-items: flex-start;

    &__collapsible-icon {
        display: flex;
        cursor: pointer;
        padding-right: math.div($unit-size-large, 3);

        &::after {
            position: relative;
            font-family: $font-family-icons;
            content: '\f054';
            font-size: $font-size-big;
            text-shadow: none;
            color: $color-text-accent;
            transition: $transition-quick;
        }

        &--expanded::after {
            transform: rotate(90deg);
        }
    }
}

/***
  * Module / User Profile Information in Comments
  */

.#{$ns}comment-user-profile-card-info {
    line-height: 1;

    &__followers {
        margin-top: $unit-size-small * 0.5;
        font-size: $font-size-tiny;
        color: $color-text-lighter;
    }
}

.#{$ns}comment-user-profile-card-unfollow {
    display: flex;
    align-items: center;
    padding: $unit-size-small * 0.5 $unit-size-small * 2;
    background-color: $color-background-light;
    border-radius: $common-border-radius;
}

.#{$ns}comment-user-profile-actions-separator {
    &::before {
        content: '|';
    }
}
