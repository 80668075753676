@import 'variables';

.adyen-checkout__dropdown__button {
    height: $unit-size-small * 7;
    border-radius: $input-field-border-radius;
    border-color: $color-input-border;

    &.adyen-checkout__dropdown__button {
        &::after {
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: none;
            content: "\f078";
            color: $color-accent-inverse;
            font-family: "Font Awesome 6 Pro";
            position: absolute;
            right: $unit-size-base;
            width: $unit-size-base;
            height: $unit-size-base;
        }

        input.adyen-checkout__filter-input {
            &:focus,
            &:active {
                box-shadow: none;
            }
        }
    }
}

.gfu-field.has-error .adyen-checkout__dropdown__button.adyen-checkout__dropdown__button {
    border-color: $color-error;
}

button.adyen-checkout__button.adyen-checkout__button--pay {
    display: none;
}
