@import 'variables';
@import 'mixins';

._bgc {
    &--error {
        background-color: $color-error;
    }

    &--primary {
        background-color: $color-primary-base;
    }

    &--primary-dark {
        background-color: $color-primary-dark;
    }

    &--light {
        background-color: $color-background-light;
    }

    &--lighter-10 {
        background-color: $color-background-lighter-10;
    }

    &--accent {
        background-color: $color-accent-base;
    }

    &--transparent {
        background-color: transparent;
    }

    &--white {
        background-color: $color-white;
    }

    &--highlight {
        background-color: $color-background-highlighted;
    }
    
    &--disabled-light {
        background-color: $color-primary-disabled-light;
    }
}

._bg-color-light {
    background-color: $color-background-light;
}
