@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Buttons
  */

$btn-size: 3*$unit-size-small;
$btn-border-width: 2px;
$btn-border-width-thin: 1px;

.#{$ns}btn {
    display: inline-block;
    line-height: 1;
    min-height: $btn-size;
    padding: $unit-size-base $unit-size-small * 3;
    border: 0;
    border-radius: $input-field-border-radius;
    outline: 0;
    margin: 0;
    user-select: none;
    font-family: $font-family-base;
    font-weight: $font-weight-extrabold;
    text-align: center;
    font-size: $font-size-small;
    text-transform: uppercase;
    text-decoration: none;
    vertical-align: middle;
    background: transparent;
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
        outline: none;
        text-decoration: none;
    }

    &:disabled,
    &[disabled="disabled"] {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
    /*
     Nunito has build in font offset 1px from bottom
     @todo: find fix for not aligned buttons with icons.
    */
    &__text {
        position: relative;
        font-size: $font-size-base;
        top: 1px;
    }

    &--soft {
        border-radius: $unit-size-large;
    }

    &--common {
        border-radius: $common-border-radius;
    }

    &--hard {
        border-radius: 0;
    }

    &--fixed {
        display: block;
        width: 100%;

        @include breakpoint('m') {
            display: inline-block;
            width: unset;
            min-width: $unit-size-large*11;
        }
    }

    &--transparent {
        background-color: transparent !important;
    }

    &--icon {
        padding: 0;
        min-height: 0;

        .#{$ns}btn {
            &__icon {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &--block {
        display: block;
        width: 100%;
    }

    &--lowercase {
        text-transform: lowercase;
    }

    &--capitalize {
        text-transform: capitalize;
    }

    &--thin {
        font-weight: normal;
    }

    &--border-thin {
        border-width: 1px !important;
    }

    &--nano {
        font-size: $font-size-nano;
    }

    &--micro {
        font-size: $font-size-micro;
        line-height: $unit-size-base + math.div($unit-size-small, 3);
        padding: $unit-size-small 2*$unit-size-small $unit-size-small;
    }

    &--tiny {
        padding: 0 math.div($unit-size-small, 3);
        font-size: $font-size-tiny;
    }

    &--large {
        font-size: $font-size-plus;
    }

    &--compact {
        min-height: 0;
        padding: $unit-size-small+$unit-size-small*0.5 3*$unit-size-small;
    }

    &--narrow {
        padding: $unit-size-base;
    }

    &--tight {
        min-height: 0;
        padding: $unit-size-small*0.5 $unit-size-large;
    }

    &--wide {
        padding-left: $unit-size-small * 3;
        padding-right: $unit-size-small * 3;

        @include breakpoint('s') {
            padding-left: $unit-size-base * 3;
            padding-right: $unit-size-base * 3;
        }
    }

    &--inline {
        vertical-align: text-bottom;
    }

    &--square {
        height: $unit-size-small * 7;
        width: $unit-size-small * 7;
        padding: $unit-size-base;
        line-height: $unit-size-small * 3 + math.div($unit-size-small, 3);
    }

    &--circle {
        font-size: $font-size-small;
        box-shadow: $box-shadow-tight;
        height: $unit-size-small * 5;
        width: $unit-size-small * 5;
        padding: 0;
        border-radius: 50px;
        flex-shrink: 0;

        &:hover,
        &--selected {
            outline: none;
        }

        &-big {
            font-size: $unit-size-small * 3;
            width: $unit-size-base * 3;
            height: $unit-size-base * 3;
            line-height: $unit-size-base * 3 - $btn-border-width * 2;
        }

        &-small {
            font-size: $unit-size-small * 2;
            width: $unit-size-base * 2;
            height: $unit-size-base * 2;
            line-height: $unit-size-base * 2 - $btn-border-width;
        }

        &-enormous {
            font-size: $font-size-enormous;
            width: $unit-size-large * 2;
            height: $unit-size-large * 2;
            line-height: $unit-size-large * 2 - $btn-border-width * 2;
        }
    }

    &--top {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &--box-shadow-tight {
        box-shadow: $box-shadow-tight;
    }

    &--toggler {
        &:not(.#{$ns}balloon__content-icon) {
            font-size: $font-size-small;
            line-height: $unit-size-small * 5;
            box-shadow: $box-shadow-tight;
            height: $unit-size-small * 5;
            width: $unit-size-small * 5;
        }

        &-emphasized {
            &:not(.#{$ns}balloon__content-icon) {
                font-size: $font-size-big;
                line-height: $unit-size-large;
            }
        }
    }

    &__icon {
        padding-right: $unit-size-small;
        line-height: 1;

        &--right {
            padding-right: 0;
            padding-left: $unit-size-small;
        }

        &--light {
            color: $color-white;
            opacity: 0.5;
        }
    }

    &--no-shadow {
        box-shadow: none !important;
    }

    &--tiny-mobile {
        float: right;

        &-text {
            @include breakpoint('m', 'max-width') {
                display: none;
            }
        }

        @include breakpoint('m', 'max-width') {
            width: $unit-size-small * 5;
            height: $unit-size-small * 5;
            padding: 0;
            border-radius: 50%;
            display: inline-block;
        }
    }

    &--nohover {
        &:hover {
            background-color: inherit;
            color: inherit;
        }
    }
}

.#{$ns}selector-btn {
    min-height: $btn-size;
    padding: $unit-size-small*0.5 2*$unit-size-small;
    min-width: $unit-size-large*5;
    outline: 0;
    user-select: none;
    font-family: $font-family-base;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid $color-primary-base;
    border-radius: $unit-size-base;
    color: $color-primary-base;
    background: $color-white;
    line-height: $line-height-base;
    text-transform: none;
    font-size: $font-size-micro;
    font-weight: $font-weight-normal;
    display: block;
    width: 100%;

    &:focus,
    &:active,
    &:hover {
        outline: none;
        text-decoration: none;
    }

    @include breakpoint('s') {
        display: inline-block;
        width: auto;
    }

    &__wrapper {
        display: flex;
        flex-grow: 1;
        align-items: center;

        .#{$ns}field__holder {
            width: auto;
        }
    }

    &--multi {
        padding: $unit-size-small*0.5 $unit-size-large $unit-size-small*0.5 $unit-size-large;
        background-color: $color-white;
        font-size: $font-size-tiny;
        display: inline-block;
        width: auto;
    }

    &--transparent {
        background-color: transparent;
    }

    &:hover {
        color: $color-white;
        background: $color-primary-base;
    }

    &:focus, &:active {
        text-decoration: none;
    }

    &--nofx {
        &:hover {
            color: $color-primary-base;
            background: $color-white;
        }
    }

    &-icon {
        // This is a trick to avoid icon in button stretching it
        height: 1px;
    }

    &:disabled,
    &[disabled="disabled"] {
        opacity: 0.5;
        cursor: default;

        &:hover {
            color: $color-primary-base;
            background: $color-white;
        }
    }

    &--active {
        color: $color-white;
        background: $color-primary-base;

        &:hover {
            color: $color-primary-base;
            background: $color-white;
        }

        &.#{$ns}selector-btn--nofx {
            &:hover {
                color: $color-white;
                background: $color-primary-base;
            }
        }

        &:disabled,
        &[disabled="disabled"] {
            opacity: 0.5;
            cursor: default;
        }
    }

    &--light {
        border-color: $color-white;
        color: $color-white;

        &.#{$ns}selector-btn--active, &:hover {
            color: $color-text-base;
            background: $color-white;
        }
    }

    &--middle {
        border-right: none;
        border-radius: 0;
    }

    &--first {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--last {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
// Color
// ============================================================================

@mixin button-color($name, $color, $bg, $color-hover: $color, $bg-hover: $bg, $border: solid 2px $color, $border-hover: $border, $filter: none) {
    .#{$ns}btn--#{$name} {
        background-color: $bg;
        color: $color;
        transition: all $transition-slow;
        filter: $filter;

        &:hover {
            @include breakpoint('l') {
                background-color: $bg-hover;
                color: $color-hover;
                border: $border-hover;
            }
        }

        &[disabled="disabled"] {
            &:hover {
                color: $color;
                background-color: $bg;
                border: $border;
            }
        }

        border: $border;
    }
}

@include button-color('primary', $color-white, $color-primary-base, $color-white, $color-primary-variant, solid $btn-border-width $color-primary-base, solid $btn-border-width $color-primary-variant);
@include button-color('accent', $color-white, $color-accent-base, $color-white, $color-accent-variant, solid $btn-border-width $color-accent-base, solid $btn-border-width $color-accent-variant);
@include button-color('primary-alt', $color-primary-base, $color-white, $color-white, $color-primary-base, solid $btn-border-width $color-primary-base, solid $btn-border-width $color-primary-base);
@include button-color('accent-alt', $color-white, $color-accent-base, $color-accent-base, $color-white, solid $btn-border-width $color-accent-base, solid $btn-border-width $color-white);
@include button-color('accent-outline', $color-accent-base, $color-white, $color-white, $color-accent-base, solid $btn-border-width $color-accent-base, solid $btn-border-width $color-accent-base);
@include button-color('light', $color-accent-base, $color-white, $color-accent-base, $color-white, solid $btn-border-width $color-accent-base, solid $btn-border-width $color-accent-base);
@include button-color('light-transparent', $color-accent-base, $color-white, $color-white, transparent, solid $btn-border-width $color-white);
@include button-color('light-inverse', $color-white, $color-accent-base, $color-accent-base, $color-text-inverse-alpha, solid $btn-border-width $color-accent-base);
@include button-color('light-alt', $color-text-gray, $color-background-light, $color-text-gray, $color-input-border, solid $btn-border-width $color-background-light, solid $btn-border-width $color-input-border);
@include button-color('secondary-inverse', $color-white, unset, $color-text-dark, $color-white, solid $btn-border-width $color-white, solid $btn-border-width $color-white);
@include button-color('secondary-alt', $color-primary-dark, $color-white, $color-primary-dark, $color-background-base, solid $btn-border-width $color-white, solid $btn-border-width $color-background-base);
@include button-color('light-outline', $color-primary-dark, $color-white, $color-primary-dark, $color-white, solid $btn-border-width-thin rgba(0,0,0,0.1), solid $btn-border-width-thin rgba(0,0,0,0.3));
@include button-color('light-flat', $color-primary-dark, $color-white, $color-white, $color-accent-base, solid $btn-border-width $color-white, solid $btn-border-width $color-accent-base);
@include button-color('disabled', $color-white, $color-secondary-disabled, $color-white, $color-secondary-disabled, solid $btn-border-width $color-secondary-disabled);
@include button-color('disabled-alt', $color-white, $color-primary-disabled, $color-white, $color-primary-disabled-dark, solid $btn-border-width $color-primary-disabled, solid $btn-border-width $color-primary-disabled-dark);
@include button-color('gray', $color-white, $color-primary-disabled, $color-white, $color-primary-base, solid $btn-border-width $color-primary-disabled, solid $btn-border-width $color-primary-base);
@include button-color('gray-alt', $color-background-light, $color-text-lighter, $color-background-light, $color-text-lighter, $color-text-lighter);
@include button-color('danger', $color-white, $color-button-danger, $color-white, $color-button-danger-dark, solid $btn-border-width $color-button-danger, solid $btn-border-width $color-button-danger-dark);
@include button-color('success', $color-white, $color-button-success, $color-white, $color-button-success-dark, solid $btn-border-width $color-button-success, solid $btn-border-width $color-button-success-dark);
@include button-color('transparent-inverse', $color-text-inverse, transparent, $color-text-inverse, $color-text-inverse-alpha, solid $btn-border-width $color-text-inverse, solid $btn-border-width $color-text-inverse);
@include button-color('search-primary', $color-text-base, $color-white, $color-text-base, $color-white, solid $btn-border-width $color-white, solid $btn-border-width $color-white);
@include button-color('search-secondary', $color-text-base, $color-selected, $color-text-base, $color-selected, solid $btn-border-width $color-selected, solid $btn-border-width $color-selected);
@include button-color('outline', $color-primary-dark, $color-white, $color-white, $color-primary-dark, solid $btn-border-width $color-primary-dark);
@include button-color('white', $color-text-gray, $color-white, $color-text-gray, $color-white, solid $btn-border-width-thin $color-background-base, drop-shadow($box-shadow-base));

/***
  * Component / Buttons Actions
  */
.#{$ns}btn-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint('m') {
        flex-direction: row;
    }

    &__item {
        margin-top: $unit-size-small * 2;

        &:first-child {
            margin-top: 0;
        }

        @include breakpoint('m') {
            margin-top: 0;
        }
    }
}

.#{$ns}processing-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: $unit-size-base;
    color: $color-text-inverse;

    @include breakpoint('l') {
        width: fit-content;
    }

    &.is-processing &__spinner {
        position: absolute;
        border-width: $unit-size-small * 0.5;
        border-style: solid;
        border-color: $color-text-inverse $color-text-inverse $color-text-inverse transparent;
        width: $unit-size-base * 2;
        height: $unit-size-base * 2;
        border-radius: 50%;
        animation: spinning 0.5s infinite linear;
    }

    &--success &__icon,
    &--error &__icon {
        position: absolute;
    }

    &.is-processing &__content,
    &--success &__content,
    &--error &__content {
        color: transparent;
    }
}

//Close button used eg. in toasts
$icon-size: $unit-size-large - math.div($unit-size-base, 3);
.#{$ns}close-btn {
    height: 20px;
    width: 20px;

    &__circle {
        width: 100%;
        height: 100%;
        stroke: $color-text-lighter;
        opacity: 0.3;
        stroke-width: 1;
        fill: transparent;
        position: absolute;
        // mathematical center != visual center
        top: -0.5px;
        left: 0;
        transform: rotate(-90deg);
        overflow: visible !important;

        circle {
            transition: 1s stroke-dashoffset linear;
        }
    }

    &__icon {
        line-height: 1;
    }
}
