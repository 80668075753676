:root {
    --color-primary-light: #{rgba(#5867dd, 0.12)};
    --color-primary-base: #5867dd;
    --color-primary-variant: #{lighten(#5867dd, 10%)};
    --color-primary-dark: #353245;
    --color-primary-disabled: #a5aac0;
    --color-primary-disabled-dark: #{darken(#a5aac0, 10%)};
    --color-primary-disabled-light: #{rgba(#a5aac0, 0.30)};
    --color-secondary-disabled: #636977;
    --color-accent-base: #1BAA64;
    --color-accent-base-disabled: #{rgba(#1BAA64, 0.5)};
    --color-accent-base-alpha: rgba(27, 170, 100, 0.15);
    --color-accent-variant: #5fc392;
    --color-accent-light: #{rgba(#1BAA64, 0.12)};
    --color-accent-lighter: #e5fcf1;
    --color-accent-inverse: #27e28b;
    --color-accent-dark: #3F9073;
    --color-dark-coral: #C26239;
    --color-dark-coral-10: #{rgba(#C26239, 0.12)};
    --color-text-gray: #636977;
    --color-text-base: #353245;
    --color-text-light: #a7b0bd;
    --color-text-lighter: #a5aac0;
    --color-text-lighter-10: rgba(165, 170, 192, 0.3);
    --color-text-light-gray : #e0e0ea;
    --color-text-dark: #1f1c2d;
    --color-text-inverse: #ffffff;
    --color-text-inverse-alpha: rgba(255, 255, 255, 0.15);
    --color-text-primary: #5867dd;
    --color-text-accent: var(--color-accent-base);
    --color-text-accent-2: var(--color-primary-base);
    --color-text-accent-3: #ff652c;
    --color-text-accent-4: #ffcb00;
    --color-text-accent-5: #353245;
    --color-text-disabled: #f2f3f8;
}
