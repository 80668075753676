@use 'variables';
@use 'mixins';

/***
  * Module / Project Summary
  */
.#{$ns}project-summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    @include breakpoint('xl') {
        flex-grow: 1;
    }
}

.#{$ns}project-summary-funded-info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $unit-size-small * 7;
    background-color: $color-accent-base;
    color: $color-white;
    border-top-left-radius: $common-border-radius;
    border-top-right-radius: $common-border-radius;
    flex-shrink: 0;

    &--outlined {
        border: 1px solid $color-white;
        border-radius: $common-border-radius;
        padding: 0 $unit-size-base;
        width: fit-content;
        margin: $unit-size-small * 3 auto 0 auto;
    }
}

/***
  * Module / Project Summary completed
  */
.#{$ns}project-summary-completed {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__main {
        flex-grow: 1;
    }

    &__aside {
        flex-grow: 0;
        flex-shrink: 0;

        @include breakpoint(m) {
            text-align: center;
        }

        @include breakpoint(xl) {
            text-align: left;
        }
    }
}

/***
  * Module / Project Summary Stats
  */
.#{$ns}project-summary-stats {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-text-inverse;
    background-color: $color-primary-disabled;
    border-radius: $common-border-radius;
    text-align: center;
    flex-wrap: wrap;

    @include box-padding();

    &--funded {
        background-color: $color-accent-base;

        @include breakpoint(xl) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: $unit-size-small * 10;
                height: $unit-size-small * 10;
                border-radius: $unit-size-small * 10;
                transform: translate(-50%, -50%);
                background: $color-accent-base url($image-path + 'projects/summary-stamp.png') no-repeat 50% 50%;
                box-shadow: $box-shadow-base;
            }
        }
    }

    &--update {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &__title {
        width: 100%;
    }

    &__follow {
        position: relative;
        z-index: 1;
        min-height: $unit-size-small * 7; // Button height
        width: 100%;
        overflow: hidden;
        border-radius: $unit-size-small * 4;

        @include breakpoint(m) {
            width: 50%;
        }

        @include breakpoint(xl) {
            width: 100%;
        }
    }

    &__content {
        position: relative;
        z-index: 1;
        width: 100%;
    }
}

.#{$ns}project-summary-stats-bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    span {
        position: absolute;
        opacity: 0.25;
        line-height: 1;
    }
    // Icons under colpleted project, so we dont have to use multiple images.
    &__thermometer-quarter {
        top: 46px;
        right: 54px;
        font-size: 40px;
    }

    &__heart-broken {
        bottom: 6px;
        right: -14px;
        font-size: 90px;
    }

    &__sad-cry {
        top: -11px;
        left: 70px;
        font-size: 80px;
    }

    &__times {
        top: -28px;
        left: 50px;
        font-size: 120px;
    }

    &__ghost {
        top: 50%;
        left: -7px;
        font-size: 56px;
        margin-top: -28px;
    }

    &__ban {
        top: 50%;
        left: -7px;
        font-size: 56px;
        margin-top: -28px;
    }

    &__lock {
        bottom: -8px;
        left: 69px;
        font-size: 46px;
    }

    &__heart {
        top: 20px;
        left: -8px;
        font-size: 72px;
    }

    &__hand-spock {
        top: 35px;
        right: 30px;
        font-size: 50px;
    }

    &__rocket {
        top: 90px;
        left: 50%;
        font-size: 32px;
    }

    &__parachute-box {
        bottom: -12px;
        left: 70px;
        font-size: 50px;
    }

    &__fire {
        bottom: -27px;
        right: -21px;
        font-size: 126px;
    }

    &__pig {
        top: 55%;
        left: 14px;
        font-size: 27px;
    }
}

/***
  * Module / Project Summary Update
  */
.#{$ns}project-summary-update {
    background-color: $color-white;
    border-bottom-left-radius: $common-border-radius;
    border-bottom-right-radius: $common-border-radius;
    padding: $unit-size-small * 2;

    @include breakpoint(m) {
        padding: $unit-size-small * 2 $unit-size-small * 3;
    }

    @include breakpoint(l) {
        padding: $unit-size-small * 2 $unit-size-small * 5;
    }

    @include breakpoint(xl) {
        border-top-right-radius: 0;
        border-bottom-left-radius: $common-border-radius;
    }
}

/***
  * Module / Project Summary Details
  */

.#{$ns}project-summary-details-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @include breakpoint(m) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -$unit-size-small;
        margin-right: -$unit-size-small;
    }

    @include breakpoint(xl) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        flex-wrap: nowrap;
    }

    &__item {
        width: 100%;

        @include breakpoint(m) {
            flex-basis: 50%;
            padding: $unit-size-small;
        }

        @include breakpoint(xl) {
            flex-basis: auto;
        }
    }
}

/***
  * Module / Project Stretch Goal
  */
.#{$ns}project-summary-stretch-goal {
    width: 100%;

    &__label {
        margin-bottom: $unit-size-small * 2 0;
    }

    &__progress {
        display: flex;
        align-items: center;
        height: $unit-size-small * 3;
    }

    &__bar {
        flex-grow: 1;
    }

    &__icon {
        width: $unit-size-small * 3;
        height: $unit-size-small * 3;
        line-height: $unit-size-small * 3;
        border-radius: $unit-size-small * 3;
        color: $color-text-inverse;
        text-align: center;
        margin-left: $unit-size-small;
        background-color: $color-primary-disabled;
        font-size: 8px; // Very small icon
    }
}

/***
  * Module / Project Stretch Goal Completed
  */

.#{$ns}project-summary-stretch-goal-completed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}


/***
  * Module / Project Summary flipper
  */

.#{$ns}project-summary-flipper {
    width: 100%;
    height: auto;
    display: flex;
    position: relative;
    perspective: 2000px;

    @include breakpoint(xl) {
        height: 100%;
    }

    &__card {
        width: 100%;
        height: 100%;
        transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform-style: preserve-3d;

        @include breakpoint('m') {
            transition-duration: 0.35s;
        }
    }

    &__front, &__back {
        display: flex;
        flex-direction: column;
        backface-visibility: hidden;
        height: 100%;

        @include breakpoint('xl') {
            @include embed-fill();
        }
    }

    &__front {
        z-index: 1;
    }

    &__back {
        display: none;
        transform: rotateX(180deg);

        @include breakpoint('xl') {
            transform: rotateY(180deg);
        }
    }

    &.is-flipped &__card {
        transform: rotateX(-180deg);

        @include breakpoint('xl') {
            transform: rotateY(-180deg);
        }
    }

    &.is-flipped &__back {
        display: flex;
        z-index: 2;
    }

    &.is-flipped &__front {
        display: none;
    }
}

/***
  * Module / Project summary progress
  */

.#{$ns}project-summary-progress {
    width: 100%;
}

/***
  * Module / Project Summary Pledge
  */

.#{$ns}project-summary-pledge {
    position: relative;
    height: 100%;

    &__close {
        position: absolute;
        top: $unit-size-small * 5;
        right: $unit-size-small * 5;
        color: $color-white;
        z-index: 2;
    }
}

/***
  * Module / Project Summary Box
  */

.#{$ns}project-summary-box {
    height: 100%;
    border-top: 4px solid $color-accent-base;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(l) {
        padding-top: ($unit-size-small * 3) - 4;
        padding-bottom: $unit-size-small * 3;
    }

    &--borderless {
        border-top: 0;

        @include breakpoint(l) {
            padding-top: ($unit-size-small * 3);
        }
    }

    &__title {
        font-size: $font-size-huge;
        font-weight: 800;
        margin: 0;
        padding: 0;
        color: $color-primary-dark;
    }

    &__sticky {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    &__header {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint('m') {
            max-width: 50%;
        }

        @include breakpoint('xl') {
            max-width: none;
        }
    }

    &__actions {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__sticky + &__progress {
        padding-top: $unit-size-small * 6;
    }
}

/***
  * Module / Project Summary Action
  */
.#{$ns}project-summary-action {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: $unit-size-small * 2;

    @include breakpoint('m') {
        max-width: $unit-size-small * 44;
    }

    @include breakpoint('xl') {
        max-width: none;
    }

    &__side {
        display: flex;
        width: $unit-size-small * 6;
        height: $unit-size-small * 7;
        justify-content: center;
        align-items: center;
        font-size: $unit-size-small * 4;
        margin-right: $unit-size-small;
    }

    &__body {
        padding-left: $unit-size-small;
        padding-right: $unit-size-small;
    }
}

/***
  * Module / Project Summary Clock
  */

.#{$ns}project-summary-clock {
    width: 100%;
    $project-summary-clock-border-size: 2px;

    position: relative;
    border-top: $project-summary-clock-border-size solid $color-border-light;
    border-bottom: $project-summary-clock-border-size solid $color-border-light;

    &__head {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
    }

    &__title {
        display: inline-block;
        padding: 0 $unit-size-small;
        background-color: $color-white;
        line-height: $font-size-super;
    }

    &__body {
        text-align: center;
        display: flex;
        justify-content: space-between;
        max-width: $unit-size-small * 44;
        padding: $unit-size-small * 2 0;
        margin: 0 auto;
    }

    &--time-part {
        text-transform: uppercase;
        font-size: $font-size-tiny;
        color: $color-text-lighter;
        line-height: $line-height-base;
    }

    &--time-value {
        font-size: $font-size-super;
        color: $color-accent-base;
        font-weight: $font-weight-extrabold;
    }
}

/***
  * Module / Project Summary Promo Item
  */
.#{$ns}project-summary-promo-item {
    width: 100%;
    color: $color-text-base;
    margin-top: $unit-size-small * 2;
}
