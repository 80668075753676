@import 'variables';
@import 'mixins';

.#{$ns}marketing-services-hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: $unit-size-base * 41;
    padding: ($unit-size-base * 9) $layout-padding-m;
    background-repeat: no-repeat;
    background-color: $color-background-dark;
    background-image:
        url('#{$image-path}marketing-services/hero-mob-tl.svg'),
        url('#{$image-path}marketing-services/hero-mob-tr.svg'),
        url('#{$image-path}marketing-services/hero-mob-br.svg'),
        url('#{$image-path}marketing-services/hero-mob-bl.svg');
    background-position: top left, top right, bottom right, bottom left;
    box-shadow: $box-shadow-base;
    color: $color-text-inverse;
    text-align: center;

    &__content {
        max-width: $unit-size-large * 19;
    }

    @include breakpoint('m') {
        background-image: url('#{$image-path}marketing-services/hero-m.svg');
        background-size: contain;
        background-position: center;
        padding: ($unit-size-base * 6) 0;
        min-height: $unit-size-base * 28;
    }
}

//Benefits section

.#{$ns}benefits-grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin: ($unit-size-small * 10) 0;

    @include breakpoint('m') {
        margin: ($unit-size-small * 6) 0;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &__stamp {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -75%);
        width: $unit-size-small * 10;
        height: $unit-size-small * 10;

        @include breakpoint('m') {
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: $unit-size-small * 10;
            background: $color-accent-base url($image-path + 'projects/summary-stamp.png') no-repeat 50% 50%;
            box-shadow: $box-shadow-base;
        }
    }
}

.#{$ns}benefit-item {
    padding: $layout-padding-l;
    box-shadow: $box-shadow-base;
    border-radius: $common-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-repeat: no-repeat, no-repeat;
    background-position: top left, bottom right;
    background-color: $color-white;

    @include breakpoint('m') {
        width: calc(50% - 5px);
    }

    &--support {
        background-image:
            url('#{$image-path}marketing-services/support-box-t-mob.svg'),
            url('#{$image-path}marketing-services/support-box-b-mob.svg');

        @include breakpoint('m') {
            background-image:
                url('#{$image-path}marketing-services/support-box-t-desk.svg'),
                url('#{$image-path}marketing-services/support-box-b-desk.svg');
        }
    }

    &--socials {
        background-image:
            url('#{$image-path}marketing-services/socials-box-t-mob.svg'),
            url('#{$image-path}marketing-services/socials-box-b-mob.svg');

        @include breakpoint('m') {
            background-image:
                url('#{$image-path}marketing-services/socials-box-t-desk.svg'),
                url('#{$image-path}marketing-services/socials-box-b-desk.svg');
        }
    }

    &--risk {
        background-image:
            url('#{$image-path}marketing-services/risk-box-t-mob.svg'),
            url('#{$image-path}marketing-services/risk-box-b-mob.svg');

        @include breakpoint('m') {
            background-image:
                url('#{$image-path}marketing-services/risk-box-t-desk.svg'),
                url('#{$image-path}marketing-services/risk-box-b-desk.svg');
        }
    }

    &--experience {
        background-image:
            url('#{$image-path}marketing-services/experience-box-t-mob.svg'),
            url('#{$image-path}marketing-services/experience-box-b-mob.svg');

        @include breakpoint('m') {
            background-image:
                url('#{$image-path}marketing-services/experience-box-t-desk.svg'),
                url('#{$image-path}marketing-services/experience-box-b-desk.svg');
        }
    }

    &__title {
        margin: 0;
        font-weight: $font-weight-extrabold;
    }
}
