@import 'variables';
@import 'mixins';

/***
  * Component / Progress bar
  */

.#{$ns}progress-bar {
    position: relative;
    background: $color-background-base;
    border-radius: $unit-size-small * 0.5;
    overflow: hidden;
    height: $unit-size-small;

    &--primary {
        background-color: $color-primary-base;
    }

    &--primary-disabled {
        background-color: $color-primary-disabled;
    }

    &--accent {
        background-color: $color-accent-base;
    }

    &--light {
        background: $color-shadow-base;
    }

    &--animated {
        transition: width 1.5s linear;
    }

    &--animated-quick {
        transition: width $transition-timing-quick ease-out;
    }

    &__progression {
        background-color: $color-white;
        padding: $unit-size-small * 0.5;
        border-radius: $unit-size-small;
        width: 0;

        &--accent {
            background-color: $color-accent-base;
        }

        &--primary {
            background-color: $color-primary-base;
        }

        &.is-transparent {
            opacity: 0;
        }
    }

    &__progression-over {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        background-color: $color-accent-base;

        &--animated {
            transition: transform 1.5s linear;
        }
    }

    &.is-over &__progression {
        background-color: $color-accent-base;
    }

    &__container {
        width: 100%;
        margin: auto;
        position: fixed;
        top: 0;
        left: 0;
        z-index: $z-floating-notification;
    }

    &__item {
        height: $unit-size-half;
        background-color: rgba(5, 114, 206, 0.1);
        width: 100%;
        overflow: hidden;
    }

    &__value {
        height: $unit-size-half;
        width: 200%;
        background: linear-gradient(
            90deg, 
            rgba(27, 170, 100, 0.3) 40%,
            #1BAA64 45%,
            rgba(27, 170, 100, 0.3) 55%,
            rgba(27, 170, 100, 0) 100%
          );
        animation: progressAnimation 2s linear infinite;
    }
}

@keyframes progressAnimation {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0%);
    }
}

/***
  * Component / Progress circle
  */

@mixin progress-circle-theme($name, $color-back, $color-front) {
    $c: '#{$ns}progress-circle';

    .#{$ns}progress-circle--#{$name} {
        .#{$c}__back {
            stroke: $color-back;
        }

        .#{$c}__front {
            stroke: $color-front;
        }
    }
}

@include progress-circle-theme('accent', $color-background-base, $color-accent-base);

/***
  * Component / timeline progress
  */

.#{$ns}timeline-bar {
    position: relative;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
    overflow-y: hidden;
    margin: 0 (-$unit-size-small * 2);
    @include breakpoint('l') {
        margin: 0;
    }

    &__wrapper {
        display: inline-flex;
        padding:  0 $unit-size-small * 2;
        @include breakpoint('l') {
            display: flex;
            padding: 0;
        }
    }

    &__item {
        width: 90vw;
        scroll-snap-align: start;
        scroll-margin-left: $unit-size-small * 2;
        @include breakpoint('l') {
            width: 100%;
        }
    }

    &__progress {
        background-color: $color-primary-disabled-light;
        height: $unit-size-small;

        &--first {
            border-radius: $unit-size-small * 0.5 0 0 $unit-size-small * 0.5;
        }

        &--last {
            border-radius: 0 $unit-size-small * 0.5 $unit-size-small * 0.5 0;
        }

        &--current {
            background-color: $color-accent-base;
        }

        &--past {
            background-color: $color-accent-base-disabled;
        }
    }
}
