@use "sass:math";
@use "sass:list";

@import 'variables';

// ============================================================================
// Breakpoint
// ============================================================================

@mixin breakpoint($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}

// ============================================================================
// Clearfix
// ============================================================================

@mixin clearfix {

    &::before,
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

// ============================================================================
// Ellipsis
// ============================================================================

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// ============================================================================
// Word-break
// ============================================================================

//noinspection CssOverwrittenProperties
@mixin wordbreak {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
}

@mixin pre-wrap {
    white-space: pre-wrap;

    @include wordbreak;

    font-family: $font-family-base;
}

// ============================================================================
// Line clamp
// ============================================================================

@mixin line-clamp($lines: 1, $prefix: '') {
    @for $prop from 1 through $lines {
        ._line-clamp-#{$prop}#{$prefix} {
            display: -webkit-box;
            -webkit-line-clamp: $prop;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

// ============================================================================
// Widths
// ============================================================================

@mixin widths($widths-suffix: '', $widths-delimiter: 'o', $widths-cols: 1 2 3 4 5 12) {
    @each $prop in $widths-cols {
        @if $prop ==0 {
            .#{$ns}#{$prop}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                width: 0;
            }

            .#{$ns}left-#{$prop}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                margin-left: 0;
            }

            .#{$ns}right-#{$prop}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                margin-right: 0;
            }
        }

        @else if $prop ==1 {
            .#{$ns}#{$prop}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                width: 100%;
            }
        }

        @else {
            @for $i from 1 to $prop {
                .#{$ns}#{$i}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                    width: math.div($i, $prop) * 100%;
                }

                .#{$ns}left-#{$i}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                    margin-left: math.div($i, $prop) * 100%;
                }

                .#{$ns}right-#{$i}#{$widths-delimiter}#{$prop}#{$widths-suffix} {
                    margin-right: math.div($i, $prop) * 100%;
                }
            }
        }
    }

    .#{$ns}w#{$widths-delimiter}gr#{$widths-suffix} {
        width: 61.618%;
    }

    .#{$ns}n#{$widths-delimiter}gr#{$widths-suffix} {
        width: 38.492%;
    }
}

// ============================================================================
// Margins
// ============================================================================

// The parameter $sizes-unit can accept a single value or an array of values
// When one value is specified, it applies the same space to all four sides.
// When two values are specified, the first space applies to the top and bottom, the second to the left and right.
// When three values are specified, the first space applies to the top, the second to the right and left, the third to the bottom.
// When four values are specified, the spaces apply to the top, right, bottom, and left in that order (clockwise).
// Usage examples:
// @include sizes('_p', 'padding', 4px, '-h');
// @include sizes('_t2', 'padding', [valH, valV], '-safe');
// @include sizes('_t3', 'padding', [valLeft, valV, valRight], '-safe');
// @include sizes('_p', 'padding', [env(safe-area-inset-left), env(safe-area-inset-top), env(safe-area-inset-right), env(safe-area-inset-bottom)], '-safe');

@mixin sizes($sizes-name, $sizes-prop, $sizes-unit, $sizes-value-name) {
    $left-value: $sizes-unit;
    $top-value: $sizes-unit;
    $right-value: $sizes-unit;
    $bottom-value: $sizes-unit;

    // If the $sizes-unit is a list, extract the correct values
    @if list-separator($sizes-unit) == comma {
        @if list.length($sizes-unit) == 1 {
            $top-value: list.nth($sizes-unit, 1);
            $bottom-value: list.nth($sizes-unit, 1);
            $left-value: list.nth($sizes-unit, 1);
            $right-value: list.nth($sizes-unit, 1);
        }
        @else if list.length($sizes-unit) == 2 {
            $top-value: list.nth($sizes-unit, 1);
            $bottom-value: list.nth($sizes-unit, 1);
            $left-value: list.nth($sizes-unit, 2);
            $right-value: list.nth($sizes-unit, 2);
        } @else if list.length($sizes-unit) == 3 {
            $left-value: list.nth($sizes-unit, 1);
            $top-value: list.nth($sizes-unit, 2);
            $right-value: list.nth($sizes-unit, 3);
            $bottom-value: list.nth($sizes-unit, 2);
        } @else if list.length($sizes-unit) == 4 {
            $left-value: list.nth($sizes-unit, 1);
            $top-value: list.nth($sizes-unit, 2);
            $right-value: list.nth($sizes-unit, 3);
            $bottom-value: list.nth($sizes-unit, 4);
        }
    }

    // Generate the CSS classes dynamically based on direction
    .#{$sizes-name}a#{$sizes-value-name} {
        #{$sizes-prop}-left: $left-value;
        #{$sizes-prop}-top: $top-value;
        #{$sizes-prop}-right: $right-value;
        #{$sizes-prop}-bottom: $bottom-value;
    }

    .#{$sizes-name}t#{$sizes-value-name} {
        #{$sizes-prop}-top: $top-value;
    }

    .#{$sizes-name}b#{$sizes-value-name} {
        #{$sizes-prop}-bottom: $bottom-value;
    }

    .#{$sizes-name}l#{$sizes-value-name} {
        #{$sizes-prop}-left: $left-value;
    }

    .#{$sizes-name}r#{$sizes-value-name} {
        #{$sizes-prop}-right: $right-value;
    }

    .#{$sizes-name}x#{$sizes-value-name} {
        #{$sizes-prop}-left: $left-value;
        #{$sizes-prop}-right: $right-value;
    }

    .#{$sizes-name}y#{$sizes-value-name} {
        #{$sizes-prop}-top: $top-value;
        #{$sizes-prop}-bottom: $bottom-value;
    }
}

// ============================================================================
// Gap
// ============================================================================

@mixin gap($prefix: '', $sizes: 1) {
    @for $size from 1 through $sizes {
        ._gap-#{$size}#{$prefix} {
            gap: ($unit-size-small * $size);
        }
    }
}

@mixin row-gap($sizes-unit, $sizes-value-name, ) {
    ._row-gap#{$sizes-value-name} {
        row-gap: $sizes-unit;
    }
}

@mixin col-gap($sizes-unit, $sizes-value-name, ) {
    ._col-gap#{$sizes-value-name} {
        column-gap: $sizes-unit;
    }
}

// ============================================================================
// z-index
// ============================================================================
@mixin z-index($prefix: '', $z-indexes: 1) {
    @for $i from 1 through $z-indexes {
        ._z-#{$i}#{$prefix} {
            z-index: $i;
        }
    }
}
// ============================================================================
// Flex Basis
// ============================================================================

@mixin flex-basis($numeric-value, $numeric-value-name) {
    ._flex-basis#{$numeric-value-name} {
        flex-basis: $numeric-value * 10%;
    }
}

// ============================================================================
// Width
// ============================================================================

@mixin width($sizes-name, $type, $numeric-value, $numeric-value-name) {
    ._#{$sizes-name}#{$numeric-value-name} {
        #{$type}: $numeric-value * 10%;
    }
}

// ============================================================================
// Height
// ============================================================================

@mixin height($sizes-name, $type, $numeric-value, $numeric-value-name) {
    ._#{$sizes-name}#{$numeric-value-name} {
        #{$type}: $numeric-value ;
    }
}

// ============================================================================
// Fonts
// ============================================================================

@mixin font-base {
    font: $font-weight-normal list.slash($font-size-base, $line-height-base) $font-family-base;
}

// ============================================================================
// Percentage helper
// ============================================================================

@mixin percentage($numeric-name, $numeric-prop, $numeric-value, $percentage-suffix: '') {
    .#{$numeric-name}#{$numeric-value}#{$percentage-suffix} {
        #{$numeric-prop}: $numeric-value * 1%;
    }
}

// ============================================================================
// Target screens
// ============================================================================

@mixin screen($screen-name, $props-name, $screen-value, $screen-suffix: '') {
    .#{$screen-name}#{$screen-suffix} {
        #{$props-name}: $screen-value;
    }
}

// ============================================================================
// Layout
// ============================================================================

@mixin layout($mode: 'standard', $offset-left: 0, $offset-right: 0) {
    margin: 0 auto;
    padding-left: $layout-padding + $offset-left;
    padding-right: $layout-padding + $offset-right;

    @include breakpoint('s') {
        padding-left: $layout-padding-s + $offset-left;
        padding-right: $layout-padding-s + $offset-right;
    }

    @include breakpoint('m') {
        padding-left: $layout-padding-m + $offset-left;
        padding-right: $layout-padding-m + $offset-right;
    }

    @include breakpoint('l') {
        padding-left: $layout-padding-l + $offset-left;
        padding-right: $layout-padding-l + $offset-right;

        @if ($mode =='standard') {
            max-width: $layout-max-width-l + $layout-padding-l * 2;
        }

        @if ($mode =='narrow') {
            max-width: $layout-max-width-l-narrow + $layout-padding-l * 2;
        }

        @if ($mode =='extranarrow') {
            max-width: $layout-width-extranarrow;
        }

        @if ($mode == 'homepage') {
            max-width: 100%;
        }
    }

    @include breakpoint('xl') {
        padding-left: $layout-padding-xl + $offset-left;
        padding-right: $layout-padding-xl + $offset-right;

        @if ($mode =='standard') {
            max-width: $layout-max-width-xl + $layout-padding-xl * 2;
        }

        @if ($mode =='narrow') {
            max-width: $layout-width-narrow;
        }

        @if ($mode =='extranarrow') {
            max-width: $layout-width-extranarrow;
        }

        @if ($mode == 'homepage') {
            max-width: $layout-max-width-xl + $layout-padding-xl * 2;
        }
    }
}

@mixin layout-space($param) {
    #{$param}: $layout-padding;

    @include breakpoint('s') {
        #{$param}: $layout-padding-s;
    }

    @include breakpoint('m') {
        #{$param}: $layout-padding-m;
    }

    @include breakpoint('l') {
        #{$param}: $layout-padding-l;
    }

    @include breakpoint('xl') {
        #{$param}: $layout-padding-xl;
    }
}

// ============================================================================
// FontAwesome
// ============================================================================

@mixin fa-light {
    font-family: $font-family-icons;
    font-weight: 300 !important;
}

@mixin fa-brands {
    font-family: $font-family-brands;
    font-weight: 300 !important;
}

// ============================================================================
// Accent colors
// ============================================================================

@mixin accent-colors {
    $accent-colors: $color-text-accent $color-text-accent-2 $color-text-accent-3 $color-text-accent-4 $color-text-accent-5;

    @for $i from 0 through (length($accent-colors) - 1) {
        $color: nth($accent-colors, $i + 1);

        &.accent-color-#{$i} {
            color: $color;
            border-color: $color;
        }
    }
}

// ============================================================================
// Embed
// ============================================================================

@mixin aspect-ratio-16-9() {
    position: relative;
    padding-bottom: 56.25%;
    /* Ratio 16:9 */
}

@mixin aspect-ratio-2-1() {
    position: relative;
    padding-bottom: 50%;
    /* Ratio 2:1 */
}

@mixin aspect-ratio-1-1() {
    position: relative;
    padding-top: 100%;
    /* Ratio 1:1 */
}

@mixin aspect-ratio-1-2() {
    position: relative;
    padding-top: 200%;
    /* Ratio 1:2 */
}

@mixin aspect-ratio-3-1() {
    position: relative;
    padding-top: 33.33%;
    /* Ratio 3:1 */
}

@mixin aspect-ratio-4-1() {
    position: relative;
    padding-top: 25%;
    /* Ratio 4:1 */
}

@mixin embed-fill($position: absolute) {
    position: $position;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

// ============================================================================
// Field focus
// ============================================================================

@mixin field-focus {
    box-shadow: $focus-shadow-base;
}

// ============================================================================
// Hightlighted
// ============================================================================

@mixin hightlighted {
    border-left: solid $unit-size-small $color-accent-base;
    background-color: $color-accent-light;
}

@mixin dehighlighted {
    transition: $transition-background-color, border-left $transition-default;
    border-left: solid $unit-size-small transparent;
}

// ============================================================================
// Arrow
// ============================================================================

@mixin arrow($color, $size, $orientation ) {
    width: 0;
    height: 0;

    @if $orientation ==top {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
    }

    @else if $orientation ==right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    }

    @else if $orientation ==down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
    }

    @else {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
    }
}

// ============================================================================
// Navbar height
// ============================================================================

@mixin navbar-height($prop: 'height', $offset: 0) {
    #{$prop}: $navbar-height-s + $offset;

    @include breakpoint('m') {
        #{$prop}: $navbar-height-m + $offset;
    }
}

// ============================================================================
// Box padding
// ============================================================================

@mixin box-padding($param: padding, $offset: 0) {
    #{$param}: $unit-size-small * 2 + $offset;

    @include breakpoint(m) {
        #{$param}: $unit-size-small * 3 + $offset;
    }

    @include breakpoint(l) {
        #{$param}: $unit-size-small * 5 + $offset;
    }
}

// ============================================================================
// Collapsible Icon
// ============================================================================

@mixin collapsible-icon() {
    &::after {
        position: absolute;
        font-family: $font-family-icons;
        content: '\f054';
        font-size: $font-size-big;
        text-shadow: none;
        color: $color-text-accent;
        transform: translateY(-50%) rotate(90deg);
        animation: 0.125s linear 0s icon-animation-up;
        left: $unit-size-small * 3;
        top: 50%;

        @content;
    }

    &--expanded {
        &::after {
            animation: 0.125s linear 0s icon-animation-down;
            transform: translateY(-50%) rotate(90deg);
        }
    }
}

@mixin translate-y($position) {
    position: absolute;
    transform: translateY($position);
}

@mixin horizontal-align($Yposition) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    #{$Yposition}: 0;
    z-index: 1;
}

@mixin position($position-prop, $sizes-unit, $sizes-value-name) {
    ._#{$position-prop}#{$sizes-value-name} {
        #{$position-prop}: $sizes-unit;
    }
}

// ============================================================================
// Grid
// ============================================================================

// SCSS Mixin for Grid
@mixin grid-cols($prefix: '', $columns: 24, $fr: $columns) {
    @for $prop from 1 through $columns {
    ._grid-cols-#{$prop}#{$prefix} {
        grid-template-columns: repeat($prop, minmax(0, #{$prop}fr));
    }
  }
}
  
// SCSS Mixin for Grid Item
@mixin grid-item($prefix: '', $span-count: 1) {
    @for $prop from 1 through $span-count {
        ._col-span-#{$prop}#{$prefix} {
            grid-column: span $prop / span $prop;
        }
    }
}

// Generate grid template row classes
@mixin row-classes($max: 6) {
    @for $i from 1 through $max {
        .grid-rows-#{$i} {
        grid-template-rows: repeat($i, minmax(0, 1fr));
        }
    }
}

@mixin auto-rows($max: 100) {
    @for $i from 1 through $max {
        ._auto-rows-#{$i} {
        grid-auto-rows: #{$i}px;
        }
    }
}

// ============================================================================
// Orders
// ============================================================================

// SCSS Mixin for Element Order

@mixin order($prefix: '', $orders: 1) {
    @for $prop from 1 through $orders {
        ._order-#{$prop}#{$prefix} {
            order: $prop
        }
    }
  }