@import 'variables';
@import 'mixins';

.#{$ns}skeleton {
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $z-modal + 1;
    }

    //--has-animation is used to animate processing data. If component doesn't have this class, it can be placeholder for empty data.
    &--has-animation {
        &::before {
            background-image: linear-gradient(to top, rgba($color-white, 0.6) 30%, rgba($color-white, 0) 100%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(to right, rgba($color-white, 0) 30%, rgba($color-white, 0.5) 70%, rgba($color-white, 0) 100%);
            background-repeat: no-repeat;
            -webkit-animation: skeletonBackgroundSwept 1s infinite linear forwards;
            -moz-animation: skeletonBackgroundSwept 1s infinite linear forwards;
            -o-animation: skeletonBackgroundSwept 1s infinite linear forwards;
            animation: skeletonBackgroundSwept 1s infinite linear forwards;
        }

        @keyframes skeletonBackgroundSwept {
            0% {
                background-position: -$unit-size-base * 24 0;
            }

            100% {
                background-position: $unit-size-base * 24 0;
            }
        }
    }
}

.#{$ns}skeleton-line {
    background: $color-skeleton-base;

    &--table {
        border: none;
        height: $unit-size-small * 3;
        background-color: $color-skeleton-base;
    }
}

.#{$ns}skeleton-button {
    background: $color-skeleton-base;
    border-radius: $unit-size-base * 2;
}
