@use "sass:math";

@import 'variables';
@import 'mixins';


/***
  * Component / Product Comparison Table
  */

@mixin theme-styles {
    &--highlight {
        background: $color-background-highlighted;
    }

    &--light {
        background: $color-background-light;
    }

    &--white {
        background: $color-background-lighter;
    }
}

.#{$ns}product-comparison-table {
    height: calc(100dvh - ($unit-size-small * 8 + $unit-size-small / 3));
    overflow: scroll;
    outline: none;

    @include breakpoint('l') {
        height: auto;
        max-height: 86dvh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: math.div($unit-size-small, 2);
    }
    
    @include breakpoint('xl') {
        margin: 0 (-$unit-size-small * 6) 0 (-$unit-size-small * 6);
    }

    &__header {
        position: sticky;
        top: 0;
        z-index: 6;
        padding-top: $unit-size-small * 2;
        background: $color-background-lighter;
        box-shadow: $drop-shadow-base;
        display: inline-flex;
    }
}

.#{$ns}product-comparison-table-separator {
    display: none;
    @include breakpoint('xl') {
        display: block;
    }

    &::after {
        content: '';
        position: absolute;
        right: -$unit-size-small;
        bottom: 0;
        width: 100%;
        height: 110%;
        background-color: $color-background-lighter;
        z-index: -1;
    }
}

.#{$ns}product-comparison-table-row-header {
    grid-column: 1 / -1;
    width: calc(100vw - $unit-size-base * 3);
    padding: 0;
    display: flex;

    @include breakpoint('xl') {
        grid-column: 1 / 1;
        margin-left: 0;
        width: auto;
    }

    @include theme-styles();

    @include breakpoint('xl') {
        &::after {
            content: '';
            position: absolute;
            top: math.div(-$unit-size-small, 2);
            right: math.div(-$unit-size-small, 2);
            width: calc(100% + $unit-size-small);
            height: 110%;
            z-index: -1;
            background-color: $color-background-lighter;
        }
    }

    &__link {
        display: flex;
        gap: $unit-size-small;
        background: $color-background-lighter;
        overflow: hidden;
        width: 100%;
        border-radius: $common-border-radius;
        box-shadow: $box-shadow-base;

        &:hover {
            text-decoration: none;
        }
    }

    &__desc {
        text-align: left;
        padding: $unit-size-small;
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        @include breakpoint('xl') {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
    }
}

.#{$ns}product-comparison-table-column-header {
    padding: 0;
    display: flex;

    &--highlight {
        position: sticky;
        z-index: 5 !important;
        top: 0;

        &::before {
            content: '';
            position: absolute;
            top: -$unit-size-small * 2;
            left: 0;
            width: 100%;
            height: $unit-size-small * 2;
            background-color: $color-white;
            z-index: 4;
        }
    }

    @include theme-styles();

    &__link {
        display: flex;
        flex-direction: column;
        margin-bottom: $unit-size-small;
        gap: $unit-size-small;
        overflow: hidden;
        width: 100%;

        &:hover {
            text-decoration: none;
        }

        > img {
            border-radius: $common-border-radius $common-border-radius 0 0;
        }
    }

    &__icon {
        height: $unit-size-small * 18;
        width: $unit-size-small * 18;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-accent;
    }
}

.#{$ns}product-comparison-table-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
    height: $unit-size-small * 6;
    font-size: $font-size-base;

    @include breakpoint('xl') {
        height: initial;
        font-size: $font-size-big;
    }

    &--highlight {
        position: sticky;
        z-index: 1;
    }

    @include theme-styles();
}

