@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Boxes
  */

$box-color-background: $color-white;
$box-padding-xs: $unit-size-small * 2;
$box-padding-l: $unit-size-small * 5;

.#{$ns}box {
    display: block;
    position: relative;
    overflow: visible;
    background-color: $box-color-background;
    border-radius: $container-border-radius;
    box-shadow: $box-shadow-base;
    color: $color-text-gray;

    > *:first-child {
        margin-top: 0;
    }

    @include box-padding();

    &--content {
        padding: 0;
    }

    &--fill {
        height: 100%;
    }

    &--lazy-fill {
        max-height: 100%;
    }

    &--error {
        color: $color-message-error;
        font-weight: $font-weight-bold;
    }

    &--flat {
        box-shadow: $box-shadow-base;
    }

    &--nested {
        box-shadow: none;
        background-color: $color-background-light;
    }

    &--thick {
        padding: $unit-size-large;

        @include breakpoint('m') {
            padding: $unit-size-large * 2;
        }
    }

    &--bulky {
        padding: $unit-size-small * 3;

        @include breakpoint('m') {
            padding: $unit-size-small * 6;
        }
    }

    &--huge {
        padding: $unit-size-small * 4;

        @include breakpoint('m') {
            padding: $unit-size-small * 6;
        }

        @include breakpoint('l') {
            padding: $unit-size-small * 12;
        }
    }

    &--slim {
        padding: $unit-size-small * 3;
    }

    &--no-fx {
        &:hover {
            text-decoration: none;
        }
    }

    &__title {
        text-align: center;

        &--success {
            color: $color-text-accent;

            &::before {
                @include fa-light;

                display: block;
                font-size: $font-size-enormous;
                content: '\f00c';
                color: $color-text-accent;
                margin-bottom: $unit-size-base;
                text-align: center;
            }
        }

        &--icon {
            &::after {
                @include fa-light;

                display: block;
                font-size: $font-size-enormous;
                margin-bottom: $unit-size-base;
                text-align: center;
            }
        }

        &--accent {
            color: $color-text-accent;
        }

        &--primary {
            color: $color-text-primary;
        }
    }

    &__section {
        padding: $unit-size-small * 3;

        @include wordbreak;

        text-align: center;
    }

    &__left {
        padding-bottom: $unit-size-large * 2;
        position: relative;

        @include breakpoint('l') {
            padding-bottom: 0;
            padding-right: $unit-size-large *2;
        }

        &--separator {
            border-bottom: 3px dotted $color-divider-base;

            @include breakpoint('l') {
                border-bottom: none;
                border-right: 3px dotted $color-divider-light;
            }
        }
    }

    &__right {
        padding-top: $unit-size-large * 2;
        position: relative;

        @include wordbreak;

        @include breakpoint('l') {
            padding: 0;
            margin-left: $unit-size-large * 2;
        }
    }

    &__content {
        @include box-padding();
    }

    // TODO: get rid of this. We have the grid for positioning and sizing elements.
    &--centered {
        @include breakpoint('l') {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            max-width: 100%;
            right: 0;
            left: 0;
        }
    }

    &__tags {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }
}

.#{$ns}box-divider {
    &::after {
        display: block;
        position: relative;
        content: '';
        height: 4px; // explicite background size for separator.
        margin-left: -$box-padding-xs;
        margin-right: -$box-padding-xs;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.12) 0%, rgba(255, 255, 255, 0) 100%);

        @include breakpoint(l) {
            margin-left: -$box-padding-l;
            margin-right: -$box-padding-l;
        }
    }

    &--content::after {
        margin: 0;
    }
}


/***
  * Component / box actions
  */

.#{$ns}box-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;

    @include breakpoint('s') {
        justify-content: space-between;
        flex-direction: row;
    }

    &__item {
        padding-top: $unit-size-base;
        width: 100%;

        @include breakpoint('s') {
            width: 45%;
        }

        @include breakpoint('m') {
            width: 30%;
        }

        @include breakpoint('l') {
            padding-top: $unit-size-large;
        }

        @include breakpoint('xl') {
            width: 25%;
        }

        &--link {
            text-align: center;

            @include breakpoint('s') {
                text-align: inherit;
            }
        }
    }
}

/***
  * Component / Header
  */

.#{$ns}box-header {
    position: relative;
    margin-bottom: $unit-size-small * 3;

    &__actions {
        position: absolute;
        top: math.div($unit-size-small, -3);
        right: 0;
    }

    &__subtitle {
        color: $color-text-lighter;
        font-size: $font-size-tiny;
    }
}
