@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Helpers / Sizes
  */

// Margins
@include sizes('_m', 'margin', 0, '-0'); // eg. _mt-0
@include sizes('_m', 'margin', auto, '-a');
@include sizes('_m', 'margin', math.div($unit-size-small, 2), '-h');
@include sizes('_m', 'margin', $unit-size-small, '-1');
@include sizes('_m', 'margin', $unit-size-small * 2, '-2');
@include sizes('_m', 'margin', $unit-size-small * 3, '-3');
@include sizes('_m', 'margin', $unit-size-small * 4, '-4');
@include sizes('_m', 'margin', $unit-size-small * 5, '-5');
@include sizes('_m', 'margin', $unit-size-small * 6, '-6');
@include sizes('_m', 'margin', $unit-size-small * 7, '-7');
@include sizes('_m', 'margin', $unit-size-small * 8, '-8');
@include sizes('_m', 'margin', $unit-size-small * 9, '-9');
@include sizes('_m', 'margin', $unit-size-small * 10, '-10');

// Negative Margins
@include sizes('_-m', 'margin', -$unit-size-small, '-1');
@include sizes('_-m', 'margin', -$unit-size-small * 2, '-2');
@include sizes('_-m', 'margin', -$unit-size-small * 3, '-3');
@include sizes('_-m', 'margin', -$unit-size-small * 4, '-4');

// Gap
$gap-counter: 8;

@include gap('', $gap-counter);

@each $name, $br in $breakpoints {
  @include breakpoint($name) {
      @include gap('--'+ $name, $gap-counter);
  }
}

// Row Gap
@include row-gap(0, '-0');
@include row-gap($unit-size-small, '-1');
@include row-gap($unit-size-small * 2, '-2');
@include row-gap($unit-size-small * 3, '-3');
@include row-gap($unit-size-small * 4, '-4');
@include row-gap($unit-size-small * 5, '-5');
@include row-gap($unit-size-small * 6, '-6');
@include row-gap($unit-size-small * 7, '-7');

// Column Gap
@include col-gap(0, '-0');
@include col-gap($unit-size-small, '-1');
@include col-gap($unit-size-small * 2, '-2');
@include col-gap($unit-size-small * 3, '-3');
@include col-gap($unit-size-small * 4, '-4');
@include col-gap($unit-size-small * 5, '-5');
@include col-gap($unit-size-small * 6, '-6');
@include col-gap($unit-size-small * 7, '-7');

// Flex Basis
@include flex-basis(0, '-0');
@include flex-basis(1, '-10');
@include flex-basis(2, '-20');
@include flex-basis(2.5, '-25');
@include flex-basis(3, '-30');
@include flex-basis(3.33, '-33');
@include flex-basis(4, '-40');
@include flex-basis(5, '-50');
@include flex-basis(6, '-60');
@include flex-basis(6.66, '-66');
@include flex-basis(7, '-70');
@include flex-basis(8, '-80');
@include flex-basis(9, '-90');
@include flex-basis(10, '-100');

// Paddings
@include sizes('_p', 'padding', 0, '-0'); // eg. _pt-0, _px-0
@include sizes('_p', 'padding', $unit-size-small * 0.5, '-h');
@include sizes('_p', 'padding', $unit-size-small * 1.5, '-o');
@include sizes('_p', 'padding', $unit-size-small, '-1');
@include sizes('_p', 'padding', $unit-size-small * 2, '-2');
@include sizes('_p', 'padding', $unit-size-small * 3, '-3');
@include sizes('_p', 'padding', $unit-size-small * 4, '-4');
@include sizes('_p', 'padding', $unit-size-small * 5, '-5');
@include sizes('_p', 'padding', $unit-size-small * 6, '-6');
@include sizes('_p', 'padding', $unit-size-small * 7, '-7');
@include sizes('_p', 'padding', [env(safe-area-inset-left), env(safe-area-inset-top), env(safe-area-inset-right), env(safe-area-inset-bottom)], '-safe');

// Borders
@include sizes('_b', 'border', none, '-0');
@include sizes('_b', 'border', 1px solid transparent, '-1');
@include sizes('_b', 'border', 2px solid transparent, '-2');
@include sizes('_b', 'border', 3px solid transparent, '-3');

// Widths
._w-screen { width: 100vw; }
@include width('w', 'width', 10, '-100');

// Max-widths
@include width('max-w', 'max-width', 10, '-100');

// Heights
._h-full { height: 100%; }
@include height('h', 'height', 50px , '-50');

// Positions top
@include position('top', auto, '-a');
@include position('bottom', $unit-size-small * 0, '-0');
@include position('right', $unit-size-small * 0, '-0');
@include position('top', $unit-size-small * 13, '-13');
@include position('right', -$unit-size-small * 2, '-n2');
