@use "sass:math";
@import 'mixins';
@import 'variables';

/***
  * Component / Alert
  */

.#{$ns}alert {
    position: relative;
    padding-left: $unit-size-small;
    border-radius: $common-border-radius;
    box-shadow: $box-shadow-base;
    
    &__wrap {
        padding: $unit-size-small * 2;
        background-color: $color-white;
        border-radius: $block-border-radius;
    }
    
    &--info {
        background-color: $color-info;
    }
    
    &--error {
        background-color: $color-error;
    }
    
    &--success {
        background-color: $color-success;
    }
    
    &--warning {
        background-color: $color-warning;
    }    
}
