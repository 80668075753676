@import 'variables';
@import 'mixins';

/***
  * Module / Project Updates
  */

.#{$ns}project-updates {
    &__wrapper {
        position: relative;
    }

    &__header {
        display: flex;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint('m') {
            text-align: right;
        }
    }

    &__user-context-filter {
        @include breakpoint('m', max-width) {
            margin-top: $unit-size-small * 2;
        }
    }

    &__header-title {
        text-align: left;

        @include breakpoint('m', max-width) {
            text-align: center;
            flex-basis: 100%;
        }
    }

    &__filters {
        position: static;
    }

    &__filters-toggle {
        display: inline-block;
        float: right;
        color: $color-text-accent;
        font-size: $font-size-tiny;
        user-select: none;
    }

    &__filters-box {
        padding-top: 0;
        padding-bottom: $unit-size-base;
    }

    //TODO: replace it with component EmptyState
    &__empty-result {
        padding: $unit-size-base * 5 0;
        min-height: $unit-size-small * 50;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

$trace-width: $unit-size-base;
$trace-border: dotted 4px $color-input-border;

$badge-width: 32px;
$badge-inner-wdith: $unit-size-base;

$wrapper-margin-top: $unit-size-base * 5;

.#{$ns}project-update {
    &__container {
        position: relative;
        margin-top: $wrapper-margin-top;
    }

    &__load-more-wrapper {
        position: relative;
        // Relative to height
        margin-top: $wrapper-margin-top - ($badge-width * 0.5);
    }

    &__trace {
        &::after {
            border-left: $trace-border;
            content: '';
            display: block;
            height: $wrapper-margin-top - ($badge-width * 0.5);
            left: calc(50% - 3px);
            position: absolute;
            top: -($wrapper-margin-top - $badge-width * 0.5);
            width: $trace-width;
        }
    }

    &__badge {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-background-light;
        border-radius: 50%;
        height: $badge-width;
        left: calc(50% - #{$badge-width * 0.5});
        position: absolute;
        top: -($badge-width * 0.5);
        width: $badge-width;
        z-index: 2;
    }

    &__badge-inner {
        background-color: $color-text-lighter;
        border-radius: 50%;
        height: $badge-inner-wdith;
        width: $badge-inner-wdith;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $unit-size-small * 3;
    }

    &__info-left {
        user-select: none;

        @include breakpoint('s', 'max-width') {
            display: flex;
            flex-basis: 100%;
            margin-bottom: $unit-size-small * 3;
            justify-content: space-between;
        }
    }

    &__info-icon {
        color: $color-text-gray;
        margin-right: $unit-size-small;

        &--active {
            color: $color-text-accent;
        }
    }

    &__info-icon-wrapper {
        display: inline-block;
    }

    &__info-right {
        width: $unit-size-large * 9;

        @include breakpoint('s', 'max-width') {
            width: 100%;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $unit-size-small * 5;

        @include breakpoint('s', 'max-width') {
            flex-wrap: wrap;
        }

        &--left {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            border-right: 1px solid $color-text-light;
            padding-right: $unit-size-small * 3;
        }

        &--right {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            padding-left: $unit-size-small * 3;
        }
    }

    &__actions-label {
        line-height: $line-height-super;
        padding-right: $unit-size-base;
        color: $color-text-light;
    }

    &__actions-icons {
        font-size: $font-size-plus;
    }

    &__title {
        font-size: $font-size-super;
        font-weight: $font-weight-extrabold;
        color: $color-text-base;

        @include wordbreak;
    }

    &__abstract {
        font-size: $font-size-plus;
        margin-top: $unit-size-small * 3;

        @include wordbreak;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $unit-size-small * 5;
    }

    &__counter {
        font-weight: $font-weight-bold;
        color: $color-text-lighter;
        text-transform: uppercase;
    }

    &__date {
        display: flex;
        color: $color-text-lighter;
        align-items: center;
    }

    &__date-icon {
        font-size: $font-size-plus;
        color: $color-text-gray;
        margin-left: ($unit-size-small * 3 * 0.5);
    }

    &__load-more {
        padding-top: $unit-size-small;
        padding-bottom: $unit-size-small;
        box-shadow: $box-shadow-tight;
        margin-top: $unit-size-base;
    }
}
