@import './variables';

/***
  * Component / Progress Indicator
  */

.#{$ns}progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: $z-progress-overlay;
    justify-content: center;
    align-items: center;
    display: none;

    &--sticky {
        position: fixed;
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $unit-size-large*2;
        height: $unit-size-large*2;
        margin: -1*$unit-size-large 0 0 -1*$unit-size-large;
        content: ' ';
        background: url('../images/site-icon.svg') no-repeat;
        background-size: $unit-size-large*2 $unit-size-large*2;
        animation: #{$ns}progress-overlay 0.3s ease-in-out infinite alternate;
        animation-iteration-count: infinite;
        transform-origin: center;
    }

    &--small {
        &::after {
            width: $unit-size-large;
            height: $unit-size-large;
            margin: -1*$unit-size-base 0 0 -1*$unit-size-base;
            background-size: $unit-size-large $unit-size-large;
        }
    }

    &.is-visible {
        display: block;
    }

    &.has-message {
        &::after {
            margin-top: $unit-size-large*-2;
        }
    }

    &__message {
        color: $color-text-gray;
        padding-top: $unit-size-small * 3;
        position: relative;
        top: 50%;
        margin: 0 auto;
        font-size: $font-size-small;
        font-weight: $font-weight-extrabold;
        cursor: default;
        text-align: center;
    }
}

.#{$ns}processing-wrapper {
    position: relative;

    &.is-active {
        pointer-events: none;

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: $unit-size-large*2;
            height: $unit-size-large*2;
            margin: -1*$unit-size-large 0 0 -1*$unit-size-large;
            content: ' ';
            background: url('../images/site-icon.svg') no-repeat;
            background-size: $unit-size-large*2 $unit-size-large*2;
            animation: #{$ns}progress-overlay 0.3s ease-in-out infinite alternate;
            animation-iteration-count: infinite;
            transform-origin: center;
        }

        & > * {
            opacity: 0.33;
        }
    }
}

@keyframes #{$ns}progress-overlay {
    from {
        opacity: 0.7;
        transform: scale(1);
    }

    to {
        opacity: 0.9;
        transform: scale(1.05);
    }
}
