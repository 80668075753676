@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Badge
  */

.#{$ns}badges {
    white-space: normal;
    text-align: left;
}

.#{$ns}badge {
    margin: 0 $unit-size-small $unit-size-small 0;
    padding: $unit-size-small*0.5 $unit-size-base;
    border-radius: $badge-border-radius;
    font-size: $font-size-micro;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $color-accent-base;
    border: 1px solid $color-accent-base;
    user-select: none;
    line-height: $line-height-enormous;

    @include accent-colors;

    &--solid {
        color: $color-white;
        background-color: $color-primary-base;
        font-size: $font-size-base;
        border: none;
    }

    &--suggestion {
        color: $color-primary-base;
        border: none;
        background-color: $color-background-light;

        &:hover, &:focus {
            background-color: $color-white;
        }
    }

    &--accent {
        color: $color-white;
        background-color: $color-accent-base;
        border: none;
    }

    &--error {
        color: $color-white;
        background-color: $color-message-error;
        border: none;
    }

    &--light {
        color: $color-white;
        background-color: $color-text-lighter;
        border: none;
    }

    &--warning {
        color: $color-black;
        background-color: $color-warning;
        border: none;
    }

    &--compact {
        font-size: $font-size-nano;
        padding: $unit-size-small*0.5 $unit-size-small;
    }

    &--has-tooltip {
        padding-right: $unit-size-large - math.div($unit-size-small, 3);
        z-index: $z-front;
    }

    &:hover {
        text-decoration: none;
    }
}

.#{$ns}badge-tooltip {
    font-size: $font-size-micro;
    margin-left: $unit-size-small * 0.5;
}

.#{$ns}cart-badge {
    position: absolute;
    top: 0;
    right: 0;
    color: $color-white;
    background-color: $color-primary-base;
    height: $unit-size-small*3;
    min-width: $unit-size-small*3;
    line-height: $unit-size-small*3;
    padding: 0 math.div($unit-size-base, 3);
    border-radius: $unit-size-base;
    font-weight: $font-weight-extrabold;
    transition: transform ease-in-out 0.25s;
    margin-top: -9px;
    margin-right: -9px;
    text-align: center;
    font-size: $font-size-small;

    &--big {
        transform: scale(1.5);
    }

    &--animated {
        animation: 2s ease 0s infinite normal none running animation-pulse;
        box-shadow: $color-primary-base 0px 0px 0px 0px;
    }
}

@keyframes animation-pulse {
    0% {
       transform: scale(0.95);
       box-shadow: rgba(88, 103, 221, 0.4) 0px 0px 0px 0px;
   }
   70% {
       transform: scale(1);
       box-shadow: rgba(88, 103, 221, 0) 0px 0px 0px 10px;
   }
   
   100% {
       transform: scale(0.95);
       box-shadow: rgba(88, 103, 221, 0) 0px 0px 0px 0px;
   } 
}