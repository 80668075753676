@import 'mixins';
@import 'variables';

/***
  * Component / Steps Indicator
  */
$step-width-base: $unit-size-base*14;

.#{$ns}steps-indicator {
    display: flex;

    &__container {
        width: 100%;
        max-width: $step-width-base;
        margin: $unit-size-base*3 auto;

        &-2 {
            max-width: $step-width-base * 5;
        }

        &-3 {
            max-width: $step-width-base*2;

            ul li {
                width: 33%;
            }
        }

        &-4 {
            max-width: $step-width-base*3;

            ul li {
                width: 25%;
            }
        }
    }

    &__progressbar {
        counter-reset: step;
        padding: 0;
        margin: 0;
    }

    &__step {
        list-style-type: none;
        width: 50%;
        float: left;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: $color-white;

        span {
            color: $color-primary-dark;
            font-size: $font-size-tiny;
            font-weight: $font-weight-extrabold;
        }

        &::before {
            width: $unit-size-base*2;
            height: $unit-size-base*2;
            content: counter(step);
            counter-increment: step;
            line-height: 1.8;
            display: block;
            text-align: center;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            background-color: $color-primary-dark;
            font-family: $font-family-base;
        }

        &::after {
            width: 100%;
            height: $unit-size-small;
            content: '';
            position: absolute;
            background-color: $color-primary-dark;
            top: 10px;
            left: -50%;
            z-index: -1;
        }

        &:first-child::after {
            content: none;
        }

        &--active {
            span {
                color: $color-primary-base;
            }

            &::before {
                background-color: $color-primary-base;
            }
        }
    }

    &__link {
        padding-top: $unit-size-base*3;
        margin-top: -$unit-size-base*3;
    }
}
